import env from 'react-dotenv';
import {useTitle} from '../hooks';
import Logo from '../images/logo.webp';
import mainBackground from '../images/background-image.webp';
import Grid from '@mui/material/Grid2';
import {LoadingButton} from '@mui/lab';
import {useAuth} from '../hooks/useAuth';
import {useEffect, useState} from 'react';
import {filterObject} from '../utils/filterObject';
import {BtnShowPassword, ForgotPassword} from '../components';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {Alert, AlertTitle, Box, Checkbox, FormControlLabel, Link, TextField} from '@mui/material';

function Login() {
    const {setTitle} = useTitle();
    const navigate = useNavigate();
    const {handleLogin, authenticated} = useAuth();
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false);

    const handleForgotPasswordClick = () => setForgotPassword(true);
    const [searchParams] = useSearchParams();
    let redirectTo = searchParams.get('redirect');

    function handleChange(event) {
        if (event.target.id === 'email') setEmail(event.target.value);

        if (errors[event.target.id]) {
            setErrors(filterObject(errors, index => index !== event.target.id));
        }
    }

    function handleSubmit(event) {
        event.preventDefault();

        const password = event.target?.password?.value;
        const remember = event.target?.remember?.checked;

        setLoading(true);

        if (redirectTo) {
            if (!redirectTo.startsWith('/') || redirectTo.includes('http') || redirectTo.includes('javascript')) {
                redirectTo = null;
            }
        }

        const redirect = () => redirectTo ? navigate(redirectTo) : null;

        handleLogin(email, password, remember, setErrors, redirect).then(() => {
            if (!authenticated) setLoading(false);
        });
    }

    useEffect(() => setTitle('Login'), [setTitle]);

    return (
        <Box className="m-auto z-10">
            <Box
                sx={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: -1,
                    opacity: .7,
                    minHeight: '100vh',
                    width: '100%',
                    backgroundColor: '#004881',
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: -2,
                    opacity: 1,
                    backgroundSize: 'cover',
                    backgroundPosition: 'top',
                    minHeight: '100vh',
                    width: '100%',
                    backgroundImage: `url(${mainBackground})`,
                }}
           />
            <Grid
                className="p-2"
                maxWidth="475px"
                component="form"
                onSubmit={handleSubmit}
            >
                <Box className="text-center">
                    <img
                        src={Logo}
                        alt={env?.APP_TITLE}
                    />
                </Box>
                {
                    redirectTo && (
                        <Alert className="mb-3 mt-0 text-white" severity="warning">
                            <AlertTitle>
                                Autenticação necessária
                            </AlertTitle>
                            Faça login para acessar a página solicitada.
                        </Alert>
                    )
                }
                <TextField
                    required
                    autoFocus
                    fullWidth
                    id="email"
                    className="mb-10"
                    placeholder="E-mail"
                    margin="normal"
                    type="email"
                    onChange={handleChange}
                    value={email}
                    error={Boolean(errors?.email)}
                    helperText={errors?.email}
                    autoComplete="username email"
                    slotProps={{
                        htmlInput: {
                            className: 'py-2.5',
                        },
                    }}
                />
                <TextField
                    required
                    fullWidth
                    id="password"
                    placeholder="Senha"
                    margin="normal"
                    type={showPassword ? 'text' : 'password'}
                    onChange={handleChange}
                    error={Boolean(errors?.password)}
                    helperText={errors?.password}
                    autoComplete="current-password"
                    slotProps={{
                        input: {
                            endAdornment: <BtnShowPassword setShow={setShowPassword} show={showPassword}/>,
                        },
                        htmlInput: {
                            className: 'py-2.5',
                        },
                    }}
                />
                <Grid container alignItems="center" justifyContent="space-between" className="mb-10 mt-1.5">
                    <Grid>
                        <FormControlLabel
                            control={<Checkbox defaultChecked={true} id="remember" color="white" className="text-white"/>}
                            label="Manter conectado"
                            slotProps={{
                                typography: {
                                    className: 'text-white text-sm',
                                },
                            }}
                        />
                    </Grid>
                    <Grid textAlign="right">
                        <Link
                            type="button"
                            variant="body2"
                            component="button"
                            className="text-white decoration-white"
                            onClick={handleForgotPasswordClick}
                        >
                            Esqueceu a senha?
                        </Link>
                    </Grid>
                </Grid>
                <LoadingButton
                    fullWidth
                    type="submit"
                    className="bg-blue-login py-2.5 text-base"
                    loading={loading}
                    disabled={Object.keys(errors).length > 0}
                >
                    Entrar
                </LoadingButton>
            </Grid>
            <ForgotPassword
                forgotPassword={forgotPassword}
                setForgotPassword={setForgotPassword}
                email={email}
                handleChange={handleChange}
                errors={errors}
                setErrors={setErrors}
            />
        </Box>
    );
}

export default Login;

export {Login};