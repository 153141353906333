import {useEffect, useState} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import {CssBaseline, useMediaQuery, useTheme} from '@mui/material';
import {NotificationsProvider} from './services';
import {
    AssetsTree,
    Dashboard,
    DetailsBreather,
    DetailsLevelDisplay,
    DetailsLubricator,
    ListBreathers,
    ListCompanies,
    ListLevelDisplays,
    ListLubricators,
    ListUsers,
    Login,
    ManageBreathers,
    ManageLevelDisplays,
    ManageLubricators,
    MyAccount,
    Notifications,
    ResetPassword,
    ListRoles,
    VerifyEmail
} from './routes';
import {DialogNotifications, Header, ListAlerts, Main, VerifyEmailAlert} from './components';
import {RequireAdmin, RequireAuth, RequireGuest} from './components/auth';

function RouteList() {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [openMobile, setOpenMobile] = useState(false);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleDrawerToggle = () => isMobile ? setOpenMobile(!openMobile) : setOpen(!open);
    const handleDrawerClose = () => isMobile ? setOpenMobile(false) : setOpen(false);

    useEffect(() => {
        if (isMobile) setOpen(false);
    }, [isMobile]);

    return (
        <BrowserRouter>
            <CssBaseline/>
            <NotificationsProvider>
                <Header
                    open={open}
                    openMobile={openMobile}
                    handleDrawerToggle={handleDrawerToggle}
                    handleDrawerClose={handleDrawerClose}
                    isMobile={isMobile}
                />
                <Main
                    open={open}
                    className="flex items-center flex-wrap lg:p-6 md:p-4 py-4 px-2 bg-grey-bg"
                >
                    <VerifyEmailAlert/>
                    <Routes>
                        <Route index path="/" element={
                            <Navigate to="/login"/>
                        }/>
                        <Route path="/login" element={
                            <RequireGuest>
                                <Login/>
                            </RequireGuest>
                        }/>
                        <Route path="/my-account" element={
                            <RequireAuth>
                                <MyAccount/>
                            </RequireAuth>
                        }/>
                        <Route path="/notifications" element={
                            <RequireAuth>
                                <Notifications/>
                            </RequireAuth>
                        }/>
                        <Route path="/dashboard" element={
                            <RequireAuth>
                                <Dashboard/>
                            </RequireAuth>
                        }/>
                        <Route path="/assets-tree" element={
                            <RequireAuth>
                                <AssetsTree/>
                            </RequireAuth>
                        }/>
                        <Route path="/lubricators" element={
                            <RequireAuth>
                                <ListLubricators/>
                            </RequireAuth>
                        }/>
                        <Route path="/breathers" element={
                            <RequireAuth>
                                <ListBreathers/>
                            </RequireAuth>
                        }/>
                        <Route path="/level-displays" element={
                            <RequireAuth>
                                <ListLevelDisplays/>
                            </RequireAuth>
                        }/>
                        <Route path="/roles" element={
                            <RequireAuth>
                                <RequireAdmin>
                                    <ListRoles/>
                                </RequireAdmin>
                            </RequireAuth>
                        }/>
                        <Route path="/lubricators/manage" element={
                            <RequireAuth>
                                <RequireAdmin>
                                    <ManageLubricators/>
                                </RequireAdmin>
                            </RequireAuth>
                        }/>
                        <Route path="/lubricators/:id" element={
                            <RequireAuth>
                                <DetailsLubricator/>
                            </RequireAuth>
                        }/>
                        <Route path="/breathers/manage" element={
                            <RequireAuth>
                                <RequireAdmin>
                                    <ManageBreathers/>
                                </RequireAdmin>
                            </RequireAuth>
                        }/>
                        <Route path="/breathers/:id" element={
                            <RequireAuth>
                                <DetailsBreather/>
                            </RequireAuth>
                        }/>
                        <Route path="/level-displays/manage" element={
                            <RequireAuth>
                                <RequireAdmin>
                                    <ManageLevelDisplays/>
                                </RequireAdmin>
                            </RequireAuth>
                        }/>
                        <Route path="/level-displays/:id" element={
                            <RequireAuth>
                                <DetailsLevelDisplay/>
                            </RequireAuth>
                        }/>
                        <Route path="/companies" element={
                            <RequireAuth>
                                <RequireAdmin>
                                    <ListCompanies/>
                                </RequireAdmin>
                            </RequireAuth>
                        }/>
                        <Route path="/users" element={
                            <RequireAuth>
                                <RequireAdmin>
                                    <ListUsers/>
                                </RequireAdmin>
                            </RequireAuth>
                        }/>
                        <Route path="/reset-password" element={<ResetPassword/>}/>
                        <Route path="/email/verify/:user_id/:email" element={
                            <RequireAuth>
                                <VerifyEmail/>
                            </RequireAuth>
                        }/>
                        <Route path="*" element={<Navigate to="/"/>}/>
                    </Routes>
                </Main>
                <DialogNotifications open={open}/>
            </NotificationsProvider>
            <ListAlerts/>
        </BrowserRouter>
    );
}

export default RouteList;

export {RouteList};