import {
    Alert,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
    TextField,
    useMediaQuery,
    useTheme
} from '@mui/material';
import BtnClose from './BtnClose';
import useAuth from '../hooks/useAuth';
import {LoadingButton} from '@mui/lab';
import {FindNewMonitor} from './FindNewMonitor';
import {useContext, useEffect, useState} from 'react';
import monitorTypes from '../dictonarys/monitorTypes';
import {AlertContext, api, TreeContext} from '../services';
import {filterObject, getAssetAppearance, uppercaseFirst} from '../utils';

function AssociateMonitor() {
    const theme = useTheme();
    const {handleLogout} = useAuth();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [monitor, setMonitor] = useState(null);
    const [duration, setDuration] = useState('');
    const [appearance, setAppearance] = useState({});
    const fullScreen = useMediaQuery(theme.breakpoints.only('xs'));
    const {associateMonitor, setAssociateMonitor, loadAssetTree} = useContext(TreeContext);
    const {newAlert} = useContext(AlertContext);
    const text = monitorTypes[associateMonitor.variant] ?? 'lubrificador';

    const handleClose = () => setAssociateMonitor({...associateMonitor, open: false});

    function clearErrors(key) {
        if (errors[key]) {
            setErrors(filterObject(errors, index => index !== key));
        }
    }

    function handleSubmit(event) {
        event.preventDefault();

        setLoading(true);

        const data = {
            parent_id: associateMonitor.id,
            parent_type: associateMonitor.type,
        };

        if (associateMonitor.variant === 'lubricator') {
            if (!duration || duration < 1 || duration > 12) {
                setErrors({
                    duration: 'A duração do lubrificador deve ser de 1 a 12 meses.',
                });
                setLoading(false);
                return;
            }

            data['duration'] = duration;
        }

        api
            .patch(`/${associateMonitor.variant}s/${monitor.id}/associate`, data)
            .then(response => {
                handleClose();
                newAlert(response?.data?.message, 'success');
                loadAssetTree(`${associateMonitor.type}-${associateMonitor.id}`);
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) setErrors(error.response?.data?.errors || {errors: {}});
                else newAlert(error.response?.data?.message ?? `Erro ao tentar associar ${text}. Tente novamente mais tarde.`);
            })
            .finally(() => setLoading(false));
    }

    function handleChange(event) {
        if (event.target.id === 'duration') setDuration(event.target.value);

        if (errors[event.target.id]) {
            setErrors(filterObject(errors, index => index !== event.target.id));
        }
    }

    useEffect(() => {
        if (!associateMonitor.open) return;

        setAppearance(getAssetAppearance(associateMonitor?.type ?? ''));
        setMonitor(null);
        setDuration('');
        // eslint-disable-next-line
    }, [associateMonitor]);

    return (
        <Dialog
            maxWidth="xs"
            fullScreen={fullScreen}
            open={Boolean(associateMonitor?.open)}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
                noValidate: true,
            }}
        >
            <DialogTitle>
                <Box>
                    Associar a{appearance.prefix === 'o' && 'o'} {appearance.type}
                </Box>
                <BtnClose onClick={handleClose}/>
            </DialogTitle>
            <DialogContent dividers>
                {
                    typeof errors[associateMonitor?.variant] !== 'undefined' && (
                        <Alert className="flex justify-center mb-2" severity="error">
                            {errors[associateMonitor.variant]}
                        </Alert>
                    )
                }
                <FindNewMonitor
                    type={associateMonitor.variant}
                    monitor={monitor}
                    setMonitor={setMonitor}
                    handleChange={() => clearErrors(associateMonitor.variant)}
                />
                {
                    associateMonitor.variant === 'lubricator' && (
                        <TextField
                            required
                            autoFocus
                            fullWidth
                            id="duration"
                            label="Duração"
                            type="number"
                            className="mt-4"
                            onChange={handleChange}
                            value={duration}
                            error={Boolean(errors?.duration)}
                            helperText={errors?.duration}
                            slotProps={{
                                input: {
                                    endAdornment: <InputAdornment position="end">mês(es)</InputAdornment>,
                                },
                            }}
                        />
                    )
                }
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    type="submit"
                    className="px-12"
                    loading={loading}
                    disabled={Object.keys(errors).length > 0 || monitor === null}
                >
                    Associar {uppercaseFirst(text)}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default AssociateMonitor;

export {AssociateMonitor};