import {useEffect} from 'react';
import {useTitle} from '../hooks';
import {TableBreathers} from '../components/TableBreathers';

function ListBreathers() {
    const {setTitle} = useTitle();

    useEffect(() => setTitle('Respiros'), [setTitle]);

    return <TableBreathers/>;
}

export default ListBreathers;

export {ListBreathers};