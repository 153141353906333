import {
    Autocomplete,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {LoadingButton} from '@mui/lab';
import useAuth from '../hooks/useAuth';
import {filterObject} from '../utils';
import {AlertContext, api} from '../services';
import {useContext, useEffect, useState} from 'react';
import {BtnClose, FindCompany, FindFolder, FindUser} from './';

function NewFolderUser({associate, setAssociate, setData, type}) {
    const {handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [role, setRole] = useState(null);
    const [user, setUser] = useState(null);
    const [errors, setErrors] = useState({});
    const [company, setCompany] = useState(null);
    const [folder, setFolder] = useState(null);
    const [loading, setLoading] = useState(false);
    const [roles, setRoles] = useState([]);

    const handeClose = () => setAssociate({...associate, open: false});

    function handleChange(event, newValue) {
        if (event.target.name === undefined) {
            setRole(newValue);
        }

        if (errors[event.target.id]) {
            setErrors(filterObject(errors, index => index !== event.target.id));
        }
    }

    function handleSubmit(event) {
        event.preventDefault();

        setLoading(true);

        api
            .post(`/folders/${folder?.id}/users/${user?.id}`, {
                role: role.id,
            })
            .then(response => {
                newAlert(response?.data?.message, 'success');

                handeClose();
                setRole(null);
                setData(data => {
                    let newData = {...data};

                    newData[`${user?.id}_${folder?.id}`] = true;

                    return newData;
                });
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) setErrors(error.response?.data?.errors || {errors: {}});
                else {
                    newAlert(error.response?.data?.message ?? `Erro ao tentar associar. Tente novamente mais tarde.`);
                }
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (associate?.id && roles.length === 0) {
            api.get('/roles/all')
                .then(response => setRoles(response.data?.data))
                .catch(error => {
                    const code = error.response?.status;

                    if (code === 401) handleLogout();
                    else newAlert(error.response?.data?.message ?? 'Erro ao carregar as funções. Tente novamente mais tarde.');
                })
        }
        // eslint-disable-next-line
    }, [associate]);

    useEffect(() => {
        if (!associate?.id) {
            setRole(null);
            setCompany(null);
            setFolder(null);
            return;
        }

        if (type === 'folder') {
            setFolder({id: associate?.id, name: associate?.name});
            setUser(null);

        } else {
            setUser({id: associate?.id, name: associate?.name});
            setFolder(null);
        }
    }, [type, associate]);

    return (
        <Dialog
            maxWidth="sm"
            open={Boolean(associate?.open)}
            onClose={handeClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
            }}
        >
            <DialogTitle>
                <Box>
                    Associar {type === 'user' ? 'pasta' : 'usuário'}
                </Box>
                <BtnClose onClick={handeClose}/>
            </DialogTitle>
            <DialogContent dividers>
                <Grid
                    container
                    className="mb-2 mt-1"
                    spacing={2}
                >
                    {
                        type === 'user' ? (
                            <>
                                <Grid size={12}>
                                    <FindCompany
                                        company={company}
                                        setCompany={setCompany}
                                        setFolder={setFolder}
                                    />
                                </Grid>
                                <Grid size={12}>
                                    <FindFolder
                                        folders={folder}
                                        company={company}
                                        setFolder={setFolder}
                                    />
                                </Grid>
                            </>
                        ) : (
                            <Grid size={12}>
                                <FindUser
                                    user={user}
                                    setUser={setUser}
                                />
                            </Grid>
                        )
                    }
                    <Grid size={12}>
                        <Autocomplete
                            fullWidth
                            value={role}
                            options={roles}
                            loading={roles.length === 0}
                            loadingText="Carregando ..."
                            onChange={handleChange}
                            noOptionsText="Nenhuma função encontrada"
                            getOptionLabel={option => option.name}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    name="role"
                                    label="Função"
                                    margin="normal"
                                    error={Boolean(errors.role)}
                                    helperText={errors.role}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    type="submit"
                    className="px-12"
                    loading={loading}
                    disabled={Boolean(errors?.role) || !folder || !user || !role}
                >
                    Associar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default NewFolderUser;

export {NewFolderUser};