import {useAuth} from '../hooks';
import BtnClose from './BtnClose';
import {LoadingButton} from '@mui/lab';
import {useContext, useState} from 'react';
import {AlertContext, api} from '../services';
import monitorTypes from '../dictonarys/monitorTypes';
import {filterObject, uppercaseFirst} from '../utils';
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField,} from '@mui/material';


function CreateOrEditMonitor({type, monitor, onClose, fullScreen, loadMonitors}) {
    const {handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const text = monitorTypes[type].text;

    function handleChange(event) {
        if (errors[event.target.id]) {
            setErrors(filterObject(errors, index => index !== event.target.id));
        }
    }

    function handleSubmit(event) {
        event.preventDefault();

        const serial = event.target?.serial?.value;
        const total_capacity = parseInt(event.target?.total_capacity?.value);
        const current_capacity = parseFloat(event.target?.current_capacity?.value);

        let data;

        if (!serial) {
            setErrors({
                serial: `O número de série do ${text} é obrigatório.`,
            });
            setLoading(false);
            return;
        }

        if (type === 'lubricator') {
            if (isNaN(total_capacity) || total_capacity < 1) {
                setErrors({
                    total_capacity: 'A capacidade precisa ser um número inteiro maior que 0.',
                });
                setLoading(false);
                return;
            }

            if (monitor?.id) {
                if (isNaN(total_capacity) || current_capacity < 0 || current_capacity > total_capacity) {
                    setErrors({
                        current_capacity: 'A capacidade atual não pode ser maior que a capacidade total.',
                    });
                    setLoading(false);
                    return;
                }

                data = {serial, total_capacity, current_capacity}
            } else {
                data = {serial, total_capacity}
            }
        } else {
            data = {serial};
        }

        let apiMethod = api.post;
        let apiEndpoint = `/${type}s`;

        if (monitor?.id) {
            apiMethod = api.put;
            apiEndpoint += '/' + monitor.id;
        }

        setLoading(true);

        apiMethod(apiEndpoint, data)
            .then(response => {
                onClose();
                newAlert(response?.data?.message, 'success');
                loadMonitors();
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) setErrors(error.response?.data?.errors || {errors: {}});
                else {
                    const action = monitor?.id ? 'editar' : 'criar';

                    newAlert(error.response?.data?.message ?? `Erro ao tentar ${action} o ${text}. Tente novamente mais tarde.`);
                }
            })
            .finally(() => setLoading(false));
    }

    return (
        <Dialog
            maxWidth="xs"
            open={Boolean(monitor?.open)}
            onClose={onClose}
            fullScreen={fullScreen}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
            }}
        >
            <DialogTitle>
                <Box>
                    {monitor?.id ? `Editar ${uppercaseFirst(text)} #${monitor?.id}` : `Novo ${text}`}
                </Box>
                <BtnClose onClick={onClose}/>
            </DialogTitle>
            <DialogContent dividers>
                <TextField
                    autoFocus
                    fullWidth
                    id="serial"
                    label="Nº de série"
                    margin="normal"
                    type="text"
                    defaultValue={monitor?.serial ?? ''}
                    onChange={handleChange}
                    error={Boolean(errors?.serial)}
                    helperText={errors?.serial}
                    slotProps={{
                        htmlInput: {
                            className: 'uppercase',
                        },
                    }}
                />
                {
                    type === 'lubricator' && (
                        <>
                            <TextField
                                fullWidth
                                id="total_capacity"
                                label="Capacidade Total"
                                margin="normal"
                                type="number"
                                defaultValue={monitor?.total_capacity ?? ''}
                                onChange={handleChange}
                                error={Boolean(errors?.total_capacity)}
                                helperText={errors?.total_capacity}
                                slotProps={{
                                    input: {
                                        endAdornment: <InputAdornment position="end">g</InputAdornment>,
                                    },
                                    htmlInput: {
                                        min: 1,
                                    },
                                }}
                            />
                            {
                                monitor?.id !== undefined && (
                                    <TextField
                                        fullWidth
                                        id="current_capacity"
                                        label="Capacidade Atual"
                                        margin="normal"
                                        type="number"
                                        defaultValue={monitor?.current_capacity ?? ''}
                                        onChange={handleChange}
                                        error={Boolean(errors?.current_capacity)}
                                        helperText={errors?.current_capacity}
                                        slotProps={{
                                            input: {
                                                endAdornment: <InputAdornment position="end">g</InputAdornment>,
                                            },
                                            htmlInput: {
                                                min: 1,
                                            },
                                        }}
                                    />
                                )
                            }
                        </>
                    )
                }
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    type="submit"
                    className="px-12"
                    loading={loading}
                    disabled={Object.keys(errors).length > 0}
                >
                    Salvar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default CreateOrEditMonitor;

export {CreateOrEditMonitor};