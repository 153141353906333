import {
    Alert,
    Box,
    Breadcrumbs,
    Card,
    Chip,
    Divider,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Skeleton,
    Typography,
    useTheme
} from '@mui/material';
import env from 'react-dotenv';
import Grid from '@mui/material/Grid2';
import {useAuth, useTitle} from '../hooks';
import {AlertContext, api} from '../services';
import {LineChart, PieChart} from '@mui/x-charts';
import {ListSubareas, RefreshedAt} from '../components';
import {useContext, useEffect, useRef, useState} from 'react';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

function Dashboard() {
    const theme = useTheme();
    const {setTitle} = useTitle();
    const {newAlert} = useContext(AlertContext);
    const {workspace, setWorkspace, handleLogout} = useAuth();
    const [loading, setLoading] = useState(true);
    const [dashboard, setDashboard] = useState({});
    const [health, setHealth] = useState(null);
    const [dates, setDates] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const interval = parseInt(localStorage.getItem('refresh') ?? env.REFRESH_DEFAULT_INTERVAL)  * 60 * 1000;
    let timeout = useRef(null);

    const handleMenu = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const icon = {
        null: <HomeIcon sx={{mr: 0.5}} fontSize="small"/>,
        true: <CorporateFareIcon sx={{mr: 0.5}} fontSize="small"/>,
        false: <FolderIcon sx={{mr: 0.5}} fontSize="small"/>,
    };

    function handleClick(id){
        handleClose();
        setWorkspace(id);
    }

    function loadDashboard() {
        clearTimeout(timeout.current);
        setLoading(true);

        api
            .get('/dashboard', {
                params: {
                    workspace,
                }
            })
            .then(response => {
                setDashboard(response.data?.data);
                timeout.current = setTimeout(loadDashboard, interval);
            })
            .catch(error => {
                if (error.response?.status === 401) handleLogout();
                else newAlert(error.response?.data?.message ?? 'Erro ao carregar o dashboard. Tente novamente mais tarde.');
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => setTitle('Página inicial'), [setTitle]);

    useEffect(() => {
        loadDashboard();

        return () => clearTimeout(timeout.current);
        // eslint-disable-next-line
    }, [workspace]);

    useEffect(() => {
        if (!('monitors' in dashboard)) return;

        setDates(() => {
            let dates = [];

            for (let i = 6; i >= 0; i--) {
                const date = new Date();

                date.setDate(date.getDate() - i);

                dates.push(date.getDate() + '/' + (date.getMonth() + 1))
            }

            return dates;
        })

        if (!dashboard.monitors) {
            setHealth(null);
            return;
        }

        const percentage = (dashboard.monitors - dashboard.alerts.errors) / dashboard.monitors;

        setHealth((Math.ceil(percentage * 10000) / 100).toLocaleString());
    }, [dashboard]);

    return (
        <Box className="self-start w-full">
            <Box className="mb-[30px]">
                {
                    loading ? (
                        <Skeleton height={42} width="100%" variant="rounded"/>
                    ) : (
                        <Breadcrumbs>
                            {
                                dashboard.location.map((folder, i) => (
                                    <Chip
                                        clickable
                                        key={i}
                                        icon={icon[folder.is_root]}
                                        label={folder.name}
                                        onClick={
                                            Object.entries(dashboard.options).length > 0 && dashboard.location.length - 1 === i ?
                                                handleMenu :
                                                () => setWorkspace(folder.id)
                                        }
                                    />
                                ))
                            }
                        </Breadcrumbs>
                    )
                }
                {
                    !loading && (
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {
                                Object.entries(dashboard.options).map(([i, folder]) => (
                                    <MenuItem key={folder.id} onClick={() => handleClick(folder.id)}>
                                        <ListItemIcon>
                                            {
                                                folder.id_root ?
                                                    <CorporateFareIcon/> :
                                                    <FolderIcon/>
                                            }
                                        </ListItemIcon>
                                        <ListItemText>{folder.name}</ListItemText>
                                    </MenuItem>
                                ))
                            }
                        </Menu>
                    )
                }
            </Box>
            <Grid container columnSpacing={3} rowSpacing={5}>
                <Grid size={12}>
                    <Typography className="font-medium text-lg">
                        Visão geral
                    </Typography>
                </Grid>
                {
                    !loading && !dashboard.monitors && (
                        <Grid size={{xs: 12}}>
                            <Alert severity="warning" className="my-0">
                                Essa área não possui ativos associados.
                            </Alert>
                        </Grid>
                    )
                }
                <Grid size={{xs: 12, lg: 4}}>
                    <Card className="p-0 flex justify-center items-center flex-col h-full">
                        <Typography className="font-medium text-lg text-center mt-6">
                            Saúde Total
                        </Typography>
                        <Grid size={11}>
                            <Divider className="my-3" sx={{borderBottom: '2px solid #F3F4F7'}}/>
                            <Typography className="text-center" variant="body2">
                                Monitore a saúde do sistema: falhas ou status normal.
                            </Typography>
                        </Grid>
                        {
                            loading ? (
                                <>
                                    <Skeleton width={210} height={210} variant="circular" className="mt-10"/>
                                    <Grid container justifyContent="space-evenly" size={12} className="my-6">
                                        <Grid>
                                            <Skeleton width={74} height={50}/>
                                        </Grid>
                                        <Grid>
                                            <Skeleton width={110} height={50}/>
                                        </Grid>
                                    </Grid>
                                </>
                            ) : (
                                <PieChart
                                    width={300}
                                    height={320}
                                    series={[
                                        {
                                            data: [
                                                {
                                                    label: 'Normal',
                                                    value: dashboard.monitors - dashboard.alerts.errors,
                                                    color: theme.palette.success.main,
                                                },
                                                {
                                                    label: 'Com Falha',
                                                    value: dashboard.alerts.errors,
                                                    color: theme.palette.error.main,
                                                },
                                            ],
                                            innerRadius: 72,
                                            outerRadius: 104,
                                            paddingAngle: 0,
                                            cornerRadius: 0,
                                            cx: 150,
                                            cy: 125,
                                        }
                                    ]}
                                    slotProps={{
                                        legend: {
                                            itemGap: 30,
                                            direction: 'row',
                                            position: {
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            },
                                        }
                                    }}
                                >
                                    <text
                                        x={146 - (health?.length ?? 0) * 5.5}
                                        y={131}
                                        className="font-semibold text-2xl"
                                    >
                                        {
                                            health === null ?
                                                '-' :
                                                health + '%'
                                        }
                                    </text>
                                    <text x={135} y={153} className="text-sm" style={{fill: '#CACACA'}}>
                                        Saúde
                                    </text>
                                </PieChart>
                            )
                        }
                    </Card>
                </Grid>
                <Grid size={{xs: 12, lg: 8}}>
                    <Card className="rounded-xl p-0 flex justify-center items-center flex-col">
                        <Typography className="font-medium text-lg text-center mt-6">
                            Diagnóstico de Falhas
                        </Typography>
                        <Grid size={11}>
                            <Divider className="my-3" sx={{borderBottom: '2px solid #F3F4F7'}}/>
                            <Grid container className="justify-center sm:justify-between" columnSpacing={1}>
                                <Typography className="w-max" variant="body2">
                                    Visualize a quantidade de ativos com falhas por dia.
                                </Typography>
                                <RefreshedAt
                                    size="auto"
                                    loading={loading}
                                    hour={dashboard?.hour}
                                    refresh={loadDashboard}
                                />
                            </Grid>
                        </Grid>
                        {
                            loading ? (
                                <Skeleton width="95%" height={300} variant="rectangular" className="my-6"/>
                            ) : (
                                <LineChart
                                    height={350}
                                    series={[
                                        {
                                            type: 'line',
                                            color: theme.palette.error.main,
                                            label: 'Ativos com Falhas',
                                            data: dashboard.history.errors,
                                        },
                                    ]}
                                    slotProps={{legend: {hidden: true}}}
                                    yAxis={[{
                                        max: Math.max(Math.ceil(Math.max.apply(null, dashboard.history.errors) * 1.20), 4),
                                        min: Math.max(Math.floor(Math.min.apply(null, dashboard.history.errors) * 0.8), 0),
                                    }]}
                                    {
                                        ...(dates.length !== 0 && {xAxis: [{scaleType: 'point', data: dates}]})
                                    }
                                />
                            )
                        }
                    </Card>
                </Grid>
            </Grid>
            <ListSubareas
                loading={loading}
                subareas={dashboard?.areas}
            />
        </Box>
    );
}

export default Dashboard;

export {Dashboard};