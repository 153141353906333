import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Tooltip,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import {
    BtnClose,
    CreateOrEditFolder,
    FilterRecords,
    StyledTableCell,
    StyledTableRow,
    TablePagination,
    TableSortLabel
} from '../components';
import env from 'react-dotenv';
import {LoadingButton} from '@mui/lab';
import cnpjMask from '../utils/cnpjMask';
import {useAuth, useTitle} from '../hooks';
import {AlertContext, api} from '../services';
import {useContext, useEffect, useRef, useState} from 'react';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';

function ListCompanies() {
    const theme = useTheme();
    const {setTitle} = useTitle();
    const {workspace, handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [loading, setLoading] = useState(true);
    const [deleting, setDeleting] = useState(false);
    const [page, setPage] = useState(0);
    const [query, setQuery] = useState('');
    const [order, setOrder] = useState('asc');
    const [perPage, setPerPage] = useState(10);
    const [orderBy, setOrderBy] = useState('folders.id');
    const [company, setCompany] = useState({open: false});
    const [companies, setCompanies] = useState({});
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [showConfirmDelete, setShowConfirmDelete] = useState({open: false});
    const interval = parseInt(localStorage.getItem('refresh') ?? env.REFRESH_DEFAULT_INTERVAL)  * 60 * 1000;
    let timeout = useRef(null);

    const confirmDelete = (id, name) => setShowConfirmDelete({open: true, id, name});
    const handeClose = () => setShowConfirmDelete({open: false});

    function deleteCompany() {
        setDeleting(true);

        api
            .delete(`/folders/${showConfirmDelete?.id}`)
            .then(response => {
                newAlert(response?.data?.message, 'success');
                setCompanies(company => {
                    const newCompanies = {...company};

                    newCompanies.data = newCompanies.data.filter(company => company.id !== showConfirmDelete?.id);
                    newCompanies.meta.total--;

                    return newCompanies;
                });
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else newAlert(error.response?.data?.message ?? 'Erro ao tentar excluir a empresa. Tente novamente mais tarde.');
            })
            .finally(() => {
                setDeleting(false);
                handeClose();
            });
    }

    function loadCompanies() {
        clearTimeout(timeout.current)
        setLoading(true);

        api
            .get('/folders', {
                params: {
                    page: page + 1,
                    per_page: perPage,
                    order,
                    order_by: orderBy,
                    query,
                },
            })
            .then(response => {
                setCompanies(response.data);
                timeout.current = setTimeout(loadCompanies, interval);
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else {
                    setCompanies({});
                    newAlert(error.response?.data?.message ?? 'Erro ao tentar carregar empresas. Tente novamente mais tarde.');
                }
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => setTitle('Empresas'), [setTitle]);

    useEffect( () => {
        loadCompanies();

        return () => clearTimeout(timeout.current);
        // eslint-disable-next-line
    }, [page, perPage, workspace, order, orderBy, query]);

    return (
        <>
            <Box className="w-full">
                <FilterRecords
                    setQuery={setQuery}
                    setPage={setPage}
                    loading={loading}
                    refresh={loadCompanies}
                    placeholder="Pesquisar por id, nome, cnpj, unidade, cidade ou estado ..."
                />
                <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="Empresas" sx={{minWidth: 950}} size="small">
                        <TableHead>
                            <TableRow>
                                {
                                    [
                                        {label: 'folders.id', text: 'ID', short: true},
                                        {label: 'folders.name', text: 'Nome', short: true},
                                        {label: 'folders.cnpj', text: 'CNPJ', short: true},
                                        {label: 'folders.unit', text: 'Unidade', short: true},
                                        {label: 'addresses.city', text: 'Cidade', short: true},
                                        {label: 'addresses.state', text: 'Estado', short: true},
                                        {label: 'actions', text: 'Ações', short: false},
                                    ].map(({label, text, short}) => (
                                        <TableSortLabel
                                            key={label}
                                            label={label}
                                            text={text}
                                            short={short}
                                            order={order}
                                            setOrder={setOrder}
                                            orderBy={orderBy}
                                            setOrderBy={setOrderBy}
                                        />
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                loading ? (
                                        new Array(perPage).fill(1).map((value, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell>
                                                    <Skeleton/>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Skeleton width={100}/>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Skeleton width={150}/>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Skeleton width={75}/>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Skeleton width={100}/>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Skeleton/>
                                                </StyledTableCell>
                                                <StyledTableCell className="flex justify-center">
                                                    <Skeleton className="mx-2"/>
                                                    <Skeleton className="mx-2"/>
                                                    <Skeleton className="mx-2"/>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    ) :
                                    (
                                        (companies && companies?.data?.map(company => (
                                            <StyledTableRow key={company?.id}>
                                                <StyledTableCell>
                                                    {company?.id}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {company?.name}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {company?.cnpj ? cnpjMask(company?.cnpj) : '-'}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {company?.unit ?? '-'}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {company?.city ?? '-'}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {company?.state?.code ?? '-'}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Tooltip title="Detalhar">
                                                        <IconButton
                                                            color="primary"
                                                            aria-label="Detalhar"
                                                            onClick={() => {
                                                                setCompany({open: true, id: company?.id, action: 'show'})
                                                            }}
                                                        >
                                                            <VisibilityIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Editar">
                                                        <IconButton
                                                            color="warning"
                                                            aria-label="Editar"
                                                            onClick={() => {
                                                                setCompany({open: true, id: company?.id, action: 'edit'})
                                                            }}
                                                        >
                                                            <EditIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Apagar">
                                                        <IconButton
                                                            color="error"
                                                            aria-label="Apagar"
                                                            onClick={() => confirmDelete(company?.id, company?.name)}
                                                        >
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )))
                                    )
                            }
                            {
                                !loading && !companies?.data?.length && (
                                    <TableRow>
                                        <TableCell colSpan={7} align="center">
                                            <Alert severity="warning">
                                                Nenhuma empresa encontrado para os dados informados.
                                            </Alert>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={7}
                                    rowsPerPage={perPage}
                                    setPerPage={setPerPage}
                                    page={page}
                                    setPage={setPage}
                                    count={companies?.meta?.total ?? 0}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                <Box className="text-end w-full mt-8">
                    <Button onClick={() => setCompany({open: true, action: 'create'})}>
                        Adicionar Empresa
                    </Button>
                </Box>
            </Box>
            <Dialog
                maxWidth="sm"
                open={Boolean(showConfirmDelete?.open)}
                onClose={handeClose}
                fullScreen={fullScreen}
            >
                <DialogTitle>
                    <Box>
                        Excluir a empresa {showConfirmDelete?.name}?
                    </Box>
                    <BtnClose onClick={handeClose}/>
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        A exclusão dessa empresa removerá todos os dados associados a ela.
                    </DialogContentText>
                    <div className="text-center font-bold mt-3">
                        ESTA AÇÃO NÃO PODERÁ SER DESFEITA!
                    </div>
                </DialogContent>
                <DialogActions className="justify-end">
                    <Button
                        autoFocus
                        variant="outlined"
                        onClick={handeClose}
                    >
                        Cancelar
                    </Button>
                    <LoadingButton
                        color="error"
                        loading={deleting}
                        onClick={deleteCompany}
                    >
                        Deletar
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <CreateOrEditFolder
                folder={company}
                setFolder={setCompany}
                fullScreen={fullScreen}
                loadFolders={loadCompanies}
                type="empresa"
            />
        </>
    );
}

export default ListCompanies;

export {ListCompanies};