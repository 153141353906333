import {
    Alert,
    AlertTitle,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    useMediaQuery,
    useTheme
} from '@mui/material';
import {useEffect, useState} from 'react';
import {api} from '../services';
import BtnClose from './BtnClose';
import {LoadingButton} from '@mui/lab';

function ForgotPassword({forgotPassword, setForgotPassword, email, handleChange, errors, setErrors, disabled = false}) {
    const theme = useTheme();
    const [alert, setAlert] = useState({});
    const [loading, setLoading] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleClose = () => {
        setForgotPassword(false);
    };

    function handleSubmit(event) {
        event.preventDefault();

        setLoading(true);
        setAlert({});

        api
            .post('/forgot-password', {email})
            .then(response => {
                setAlert({
                    severity: 'success',
                    title: response?.data?.title,
                    message: response?.data?.message,
                })
            })
            .catch(error => {
                if (error.response?.status === 422) {
                    const {errors} = error.response?.data || {errors: {}};

                    setErrors(errors);
                } else {
                    setAlert({
                        severity: 'error',
                        title: error.response?.data?.title ?? 'Erro ao enviar e-mail.',
                        message: error.response?.data?.message ?? 'Não foi possível enviar o e-mail, tente novamente mais tarde.',
                    })
                }
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (forgotPassword) {
            setAlert({});
        }
    }, [forgotPassword]);

    return (
        <Dialog
            maxWidth="xs"
            open={forgotPassword}
            onClose={handleClose}
            fullScreen={fullScreen}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
            }}
        >
            <DialogTitle>
                <Box>
                    Redefinir Senha
                </Box>
                <BtnClose onClick={handleClose}/>
            </DialogTitle>
            <DialogContent dividers>
                {
                    typeof alert.message !== 'undefined' && (
                        <Alert severity={alert.severity} className="mb-3">
                            <AlertTitle>
                                {alert.title}
                            </AlertTitle>
                            {alert.message}
                        </Alert>
                    )
                }
                <TextField
                    required
                    autoFocus
                    fullWidth
                    id="email"
                    placeholder="E-mail"
                    type="email"
                    onChange={handleChange}
                    value={email}
                    error={Boolean(errors?.email)}
                    helperText={errors?.email}
                    disabled={disabled}
                    autoComplete="username email"
                    slotProps={{
                        htmlInput: {
                            className: 'py-2.5',
                        },
                    }}
                />
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    type="submit"
                    className="px-14"
                    loading={loading}
                    disabled={Boolean(errors?.email)}
                >
                    Enviar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default ForgotPassword;

export {ForgotPassword};