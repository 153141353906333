import {useEffect} from 'react';
import useTitle from '../hooks/useTitle';
import TableBreathers from '../components/TableBreathers';

function ManageBreathers() {
    const {setTitle} = useTitle();

    useEffect(() => setTitle('Respiro', 'Gerenciar Respiro'), [setTitle]);

    return <TableBreathers manage={true}/>;
}

export default ManageBreathers;

export {ManageBreathers};