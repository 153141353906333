import {ListItem as ListItemMUI, ListItemButton, ListItemIcon, ListItemText, styled} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';

const StyledListItemButton = styled(ListItemButton)(({theme}) => ({
    '&:hover': {
        fontWeight: 500,
        background: theme.palette.action.gradient.hover,
    },
    '&.Mui-selected': {
        fontWeight: 600,
        background: theme.palette.action.gradient.selected,
    },
    '&:hover, &.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
        paddingLeft: 12,
        paddingRight: 16,
        color: theme.palette.primary.main,

        borderLeft: `4px solid ${theme.palette.primary.main}`,
    },
}));

function ListItem({text, Icon, route, regEx, onClick = () => null}) {
    const local = useLocation();
    const navigate = useNavigate();
    const regex = new RegExp(regEx ?? route);

    return (
        <ListItemMUI
            key={text}
            disablePadding
            sx={{borderBottom: 'none',}}
            onClick={() => {
                onClick();
                navigate(route);
            }}
        >
            <StyledListItemButton selected={regex.test(local.pathname)}>
                <ListItemIcon>
                    <Icon/>
                </ListItemIcon>
                <ListItemText primary={text} primaryTypographyProps={{fontWeight: 'inherit'}}/>
            </StyledListItemButton>
        </ListItemMUI>
    );
}

export default ListItem;

export {ListItem};