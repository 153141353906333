import {useEffect} from 'react';
import {List, Paper} from '@mui/material';
import {useNotifications, useTitle} from '../hooks';
import {ListNotifications, TablePagination} from '../components';

function Notifications() {
    const {setTitle} = useTitle();
    const {page, setPage, perPage, setPerPage, notifications} = useNotifications();

    useEffect(() => setTitle('Notificações'), [setTitle]);

    return (
        <Paper className="w-full px-2 md:px-4 lg:px-6">
            <List disablePadding className="w-full">
                <ListNotifications close={false}/>
                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    rowsPerPage={perPage}
                    setPerPage={setPerPage}
                    page={page}
                    setPage={setPage}
                    count={notifications?.meta?.total ?? 0}
                />
            </List>
        </Paper>
    );
}

export default Notifications;

export {Notifications}