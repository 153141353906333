import {
    Box,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Skeleton,
    Stack
} from '@mui/material';
import {useAuth} from '../hooks';
import BtnClose from './BtnClose';
import {LoadingButton} from '@mui/lab';
import {AlertContext, api} from '../services';
import {useContext, useEffect, useState} from 'react';

function MangePermissions({manage, onClose, fullScreen, loadRoles}) {
    const {handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [loading, setLoading] = useState(false);
    const [loadingPermissions, setLoadingPermissions] = useState(false);
    const [permissions, setPermissions] = useState([]);

    function handleSubmit(event) {
        event.preventDefault();

        setLoading(true)

        const data = new FormData(event.currentTarget);

        api.patch(`/roles/${manage.data.id}/permissions`, {
            permissions: data.getAll('permissions'),
        })
            .then(response => {
                onClose();
                newAlert(response?.data?.message, 'success');
                loadRoles();
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else {
                    newAlert(error.response?.data?.message ?? 'Erro ao tentar salvar as permissões. Tente novamente mais tarde.');
                }
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (manage?.open && permissions.length === 0) {
            setLoadingPermissions(true);

            api.get('/permissions')
                .then(response => setPermissions(response.data?.data))
                .catch(error => {
                    const code = error.response?.status;

                    if (code === 401) handleLogout();
                    else {
                        newAlert(error.response?.data?.message ?? 'Erro ao tentar carregar as permissões. Tente novamente mais tarde.');
                    }
                })
                .finally(() => setLoadingPermissions(false));
        }
        // eslint-disable-next-line
    }, [manage]);

    return (
        <Dialog
            maxWidth="xs"
            open={Boolean(manage?.open)}
            onClose={onClose}
            fullScreen={fullScreen}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
            }}
        >
            <DialogTitle>
                <Box>
                    Permissões
                </Box>
                <BtnClose onClick={onClose}/>
            </DialogTitle>
            <DialogContent dividers>
                <Stack>
                    {
                        loadingPermissions ? (
                            <Stack>
                                {
                                    new Array(10).fill(1).map((value, index) => (
                                        <Box className="flex mb-4 last:mb-0">
                                            <Skeleton width={20} height={20} className="mr-2"/>
                                            <Skeleton width={150} height={20} className="ml-0.5"/>
                                        </Box>
                                    ))
                                }
                            </Stack>
                        ) : (
                            permissions.map(permission => (
                                <FormControlLabel
                                    label={permission.name}
                                    key={permission.id}
                                    control={
                                        <Checkbox
                                            id={`permission-${permission.id}`}
                                            name="permissions"
                                            value={permission.id}
                                            defaultChecked={manage.data.permissions.includes(permission.id)}
                                        />
                                    }
                                />
                            ))
                        )
                    }
                </Stack>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    type="submit"
                    className="px-12"
                    loading={loading}
                >
                    Salvar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default MangePermissions;

export {MangePermissions};