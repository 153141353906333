import {api} from '../services';
import Grid from '@mui/material/Grid2';
import {LoadingButton} from '@mui/lab';
import {useEffect, useState} from 'react';
import {useTitle} from '../hooks/useTitle';
import {BtnShowPassword} from '../components';
import {useSearchParams} from 'react-router-dom';
import {Alert, AlertTitle, Box, CircularProgress, TextField} from '@mui/material';

function ResetPassword() {
    const {setTitle} = useTitle();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState({});
    const [valid, setValid] = useState(null);
    const [resetting, setResetting] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [searchParams] = useSearchParams();
    const email = searchParams.get('email');
    const token = searchParams.get('token');
    const handleChange = () => errors ? setErrors({}) : null;

    useEffect(() => setTitle('Redefinir Senha'), [setTitle]);

    useEffect(() => {
        function setInvalid() {
            setValid(false);
            setAlert({
                severity: 'error',
                title: 'Link inválido.',
                message: 'O link para redefinição de senha é inválido ou expirou.',
            });
        }

        api
            .get('/reset-password', {
                params: {
                    token,
                    email
                }
            })
            .then(response => {
                if (!response?.data?.valid) setInvalid();
                else setValid(true);
            })
            .catch(setInvalid)
            .finally(() => setLoading(false));
        // eslint-disable-next-line
    }, []);

    function handleSubmit(event) {
        event.preventDefault();

        setResetting(true);

        const password = event.target?.password?.value;
        const password_confirmation = event.target?.password_confirmation?.value;

        if (password !== password_confirmation) {
            setErrors({
                password: 'As senhas não conferem.',
                password_confirmation: 'As senhas não conferem.',
            });
            setResetting(false);
            return;
        }

        api
            .post('/reset-password', {
                token,
                email,
                password,
                password_confirmation,
            })
            .then(response => {
                setAlert({
                    severity: 'success',
                    title: response?.data?.title,
                    message: response?.data?.message,
                });
                setValid(null);
            })
            .catch(error => {
                if (error.response?.status === 422) setErrors(error.response?.data?.errors || {errors: {}});
                else setAlert({
                    severity: 'error',
                    title: 'Erro ao redefinir senha.',
                    message: 'Não foi possível redefinir a senha, tente novamente mais tarde.',
                });
            })
            .finally(() => setResetting(false));
    }


    if (loading) return <CircularProgress className="m-auto"/>;

    return (
        <Box className="mx-auto">
            {
                typeof alert.message !== 'undefined' && (
                    <Alert className="mb-3 py-10" severity={alert.severity}>
                        <AlertTitle>
                            {alert.title}
                        </AlertTitle>
                        {alert.message}
                    </Alert>
                )
            }
            {
                valid === true && (
                    <Grid
                        className="p-2"
                        maxWidth="475px"
                        component="form"
                        onSubmit={handleSubmit}
                    >
                        <TextField
                            disabled
                            fullWidth
                            id="email"
                            label="E-mail"
                            margin="normal"
                            type="email"
                            value={email}
                            autoComplete="username email"
                        />
                        <TextField
                            required
                            autoFocus
                            fullWidth
                            id="password"
                            className="my-10"
                            label="Nova senha"
                            margin="normal"
                            type={showPassword ? 'text' : 'password'}
                            onChange={handleChange}
                            error={Boolean(errors?.password)}
                            helperText={errors?.password}
                            autoComplete="new-password"
                            slotProps={{
                                input: {
                                    endAdornment: <BtnShowPassword setShow={setShowPassword} show={showPassword}/>,
                                },
                            }}
                        />
                        <TextField
                            required
                            fullWidth
                            id="password_confirmation"
                            label="Confirme a nova senha"
                            margin="normal"
                            type={showPassword ? 'text' : 'password'}
                            onChange={handleChange}
                            error={Boolean(errors?.password_confirmation)}
                            helperText={errors?.password_confirmation}
                            autoComplete="new-password"
                            slotProps={{
                                input: {
                                    endAdornment: <BtnShowPassword setShow={setShowPassword} show={showPassword}/>,
                                },
                            }}
                        />
                        <LoadingButton
                            fullWidth
                            type="submit"
                            id="btn-submit"
                            className="py-2.5 text-base mt-11"
                            loading={resetting}
                            disabled={Object.keys(errors).length > 0}
                        >
                            Trocar Senha
                        </LoadingButton>
                    </Grid>
                )
            }
        </Box>
    );
}

export default ResetPassword;

export {ResetPassword};