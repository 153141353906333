import Grid from '@mui/material/Grid2';
import {useParams} from 'react-router-dom';
import {useAuth, useTitle} from '../hooks';
import {useContext, useEffect, useState} from 'react';
import {AlertContext, api, TreeContext} from '../services';
import levelDisplayStatuses from '../dictonarys/levelDisplayStatuses';
import {Box, Breadcrumbs, Button, Chip, Paper, Skeleton, Typography} from '@mui/material';
import {ActiveAlerts, ConfirmDisassociateMonitor, LatestStatus, RefreshedAt} from '../components';
import CircleIcon from '@mui/icons-material/Circle';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturingOutlined';

function DetailsLevelDisplay() {
    const {id} = useParams();
    const {setTitle} = useTitle();
    const {handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [loading, setLoading] = useState(true);
    const [levelDisplay, setLevelDisplay] = useState({});
    const {setShowConfirmDisassociate} = useContext(TreeContext);

    function loadLevelDisplay() {
        setLoading(true);

        api.get('/level-displays/' + id)
            .then(response => setLevelDisplay(response?.data?.data))
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 404) newAlert('O visor de nível solicitado não foi encontrado.');
                else if (code === 403) newAlert('Você não tem permissão para acessar os detalhes desse visor de nível.');
                else newAlert('Erro ao tentar carregar os detalhes desse visor de nível. Tente novamente mais tarde.');

                setLevelDisplay({});
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => setTitle('Ver Visor de Nível', 'Detalhes do visor de nível #' + id), [id, setTitle]);

    // eslint-disable-next-line
    useEffect(loadLevelDisplay, [id]);


    if (!loading && !levelDisplay) return;

    return (
        <>
            <Box className="self-start w-full">
                <Box className="my-2">
                    {
                        loading ? (
                            <Skeleton height={42} width="100%" variant="rounded"/>
                        ) : (
                            levelDisplay.location ? (
                                <Breadcrumbs>
                                    {
                                        levelDisplay.location?.folders?.map((folder, i) => (
                                            <Chip
                                                key={i}
                                                label={folder}
                                                icon={i === 0 ?
                                                    <CorporateFareIcon fontSize="small" /> :
                                                    <FolderIcon fontSize="small" />
                                                }
                                            />
                                        ))
                                    }
                                    {
                                        levelDisplay?.location?.machine && (
                                            <Chip
                                                icon={<PrecisionManufacturingIcon fontSize="small" />}
                                                label={levelDisplay.location.machine}
                                            />
                                        )
                                    }
                                    {
                                        levelDisplay?.location?.subset && (
                                            <Chip
                                                icon={<SettingsIcon fontSize="small" />}
                                                label={levelDisplay.location.subset}
                                            />
                                        )
                                    }
                                    {
                                        levelDisplay?.location?.component && (
                                            <Chip
                                                icon={<RadioButtonCheckedIcon fontSize="small" />}
                                                label={levelDisplay.location.component}
                                            />
                                        )
                                    }
                                </Breadcrumbs>
                            ) : (
                                <Typography
                                    variant="caption"
                                    component="span"
                                    className="font-bold"
                                >
                                    Visor de nível não instalado
                                </Typography>
                            )
                        )
                    }
                </Box>
                <Grid container columnSpacing={3}>
                    <Grid container size={{xs: 12, lg: 'grow'}}>
                        <Grid container size={12} className="my-6 items-end">
                            <Grid size={6}>
                                <Typography variant="h6" className="font-medium text-primary-main">
                                    Informações
                                </Typography>
                            </Grid>
                            <Grid size={6}>
                                <RefreshedAt
                                    loading={loading}
                                    hour={levelDisplay?.hour}
                                    refresh={loadLevelDisplay}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            size={12}
                            spacing={4}
                            className="p-9 rounded-lg bg-blue-bg mb-8 justify-center"
                            sx={(theme) => ({border: `1px solid ${theme.palette.primary.main}`})}
                        >
                            <Grid size={{xs: 12, md: 6}}>
                                <Typography className="text-primary-main">
                                    Número de Série
                                </Typography>
                                <Paper className="p-2 text-center rounded text-sm">
                                    {
                                        loading ? (
                                            <Skeleton width={250}/>
                                        ) : levelDisplay.serial
                                    }
                                </Paper>
                            </Grid>
                            <Grid size={{xs: 12, md: 6}}>
                                <Typography className="text-primary-main">
                                    ID
                                </Typography>
                                <Paper className="p-2 text-center rounded text-sm">
                                    {
                                        loading ? (
                                            <Skeleton/>
                                        ) : `#${levelDisplay.id}`
                                    }
                                </Paper>
                            </Grid>
                            <Grid size={{xs: 12, md: 6}}>
                                <Typography className="text-primary-main">
                                    Status
                                </Typography>
                                <Paper className="p-2 flex items-center justify-center rounded text-sm">
                                    {
                                        loading ? (
                                            <>
                                                <Skeleton variant="circular" className="mr-1"/>
                                                <Skeleton width={80} className="ml-1"/>
                                            </>
                                        ) : (
                                            <>
                                                <CircleIcon className="mr-2" sx={{color: levelDisplayStatuses[levelDisplay.status].color}}/>
                                                {levelDisplayStatuses[levelDisplay.status].text}
                                            </>
                                        )
                                    }
                                </Paper>
                            </Grid>
                        </Grid>
                        <LatestStatus levelDisplay={id} battery={false}/>
                    </Grid>
                    <Grid
                        container
                        size={{xs: 12, lg: 3}}
                        className="px-2.5 py-3.5 rounded-lg bg-blue-bg mt-8 lg:mt-[100.5px] flex-col"
                        sx={theme => ({
                            minWidth: 239,
                            border: `1px solid ${theme.palette.primary.main}`,
                        })}
                    >
                        <Typography variant="h6" className="font-medium text-primary-main mx-auto pt-2 mb-7">
                            Painel de Alertas
                        </Typography>
                        <ActiveAlerts
                            type="level-display"
                            loading={loading}
                            activeAlerts={levelDisplay?.active_alerts}
                        />
                        {
                            (loading || (levelDisplay.location && levelDisplay.disassociate)) && (
                                <Box className="mt-2 lg:mt-auto text-center">
                                    {
                                        loading ? (
                                            <Skeleton width={220} height={37} variant="rounded"/>
                                        ) : (
                                                <>
                                                <Box className="self-end w-full">
                                                    <Button
                                                        color="error"
                                                        variant="outlined"
                                                        onClick={() => setShowConfirmDisassociate({
                                                            open: true,
                                                            type: 'levelDisplay',
                                                            id: levelDisplay.id,
                                                        })}
                                                    >
                                                        Remover visor de nível
                                                    </Button>
                                                </Box>
                                                <ConfirmDisassociateMonitor callback={loadLevelDisplay}/>
                                            </>
                                        )
                                    }
                                </Box>
                            )
                        }
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default DetailsLevelDisplay;

export {DetailsLevelDisplay};