import {useEffect} from 'react';
import useTitle from '../hooks/useTitle';
import TableLevelDisplays from '../components/TableLevelDisplays';

function ManageLevelDisplays() {
    const {setTitle} = useTitle();

    useEffect(() => setTitle('Visores de Nível', 'Gerenciar Visores de Nível'), [setTitle]);

    return <TableLevelDisplays manage={true}/>;
}

export default ManageLevelDisplays;

export {ManageLevelDisplays};