import RouteList from './RouteList';
import {createRoot} from 'react-dom/client';
import {CookiesProvider} from 'react-cookie'
import reportWebVitals from './reportWebVitals';
import {AlertsProvider, AuthProvider, FirebaseProvider, TitleProvider, TreeProvider} from './services';
import {createTheme, responsiveFontSizes, StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import './styles/index.css';

const root = createRoot(document.getElementById('root'));

let theme = createTheme({
    cssVariables: true,
    palette: {
        primary: {
            light: '#0874BB',
            main: '#004881',
            dark: '#014882',
            gradient: 'linear-gradient(to right, #004881, #0874BB)',
        },
        action: {
            gradient: {
                hover: 'linear-gradient(to right, rgba(1, 72, 130, .15), rgba(8, 116, 187, .05))',
                selected: 'linear-gradient(to right, rgba(1, 72, 130, .3), rgba(8, 116, 187, .1))',
            },
        },
        success: {
            100: '#82E27A',
            200: '#39D42B',
            500: '#259D1B',
            main: '#259D1B',
            alert: '#87F57E',
            tree: '#259D1B',
        },
        error: {
            100: '#FFC6C6',
            200: '#FE8383',
            500: '#DE4848',
            main: '#DE4848',
            alert: '#FE8383',
            tree: '#DE4848',
        },
        warning: {
            500: '#FFBF34',
            main: '#FFBF34',
            alert: '#FFCF67',
            tree: '#E9AE30',
        },
        white: {
            500: '#FFF',
            main: '#FFF',
        },
        text: {
            primary: '#5C5C5C',
        },
    },
    typography: {
        button: {
            textTransform: 'none',
        },
        fontFamily: 'Poppins, sans-serif',
    },
    shape: {
        borderRadius: '0.5rem',
    },
    components: {
        MuiAlert: {
            defaultProps: {
                iconMapping: {
                    success: <CheckCircleIcon sx={{fontSize: 32}}/>,
                    warning: <WarningIcon sx={{fontSize: 32}}/>,
                    error: <WarningIcon sx={{fontSize: 32}}/>,
                    info: <InfoIcon sx={{fontSize: 32}}/>,
                },
            },
            styleOverrides: {
                standard: {
                    padding: '18px',
                    fontSize: '16px',
                    margin: '17px 0',
                    borderRadius: '0',
                },
                message: {
                    display: 'flex',
                    alignItems: 'start',
                    justifyContent: 'center',
                    flexDirection: 'column',
                },
                action: {
                    display: 'flex',
                    alignItems: 'center',
                    paddingTop: 0,
                },
            },
        },
        MuiBreadcrumbs: {
            defaultProps: {
                separator: <ArrowForwardIosSharpIcon sx={{color: '#CACACA'}} fontSize="large"/>,
            },
            styleOverrides: {
                root: ({theme}) => ({
                    maxWidth: '100%',
                    width: 'max-content',
                    backgroundColor: theme.palette.background.default,
                    borderRadius: '1rem',
                    border: '1px solid #CACACA',
                }),
                li: ({theme}) => ({
                    '&:last-child > .MuiChip-root': {
                        color: theme.palette.primary.main,
                    },
                }),
            },
        },
        MuiChip: {
            defaultProps: {
                color: 'primary',
                clickable: false,
            },
            styleOverrides: {
                root: ({theme}) => ({
                    fontWeight: 500,
                    fontSize: '14px',
                    padding: '20px',
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.text.primary,
                    variants: [
                        {
                            props: {clickable: true},
                            style: {
                                '&:hover': {
                                    background: theme.palette.action.gradient.hover,
                                },
                            },
                        },
                    ],
                }),
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: ({theme}) => ({
                    color: theme.palette.primary.main,
                    backgroundColor: '#CACACA',
                }),
            },
        },
        MuiCheckbox: {
            defaultProps: {
                size: '16px',
                className: 'ml-0.5',
            },
        },
        MuiFormControlLabel: {
            defaultProps: {
                slotProps: {
                    typography: {
                        variant: 'caption',
                        className: 'font-light',
                        sx: {
                            marginLeft: '-1px',
                        },
                    },
                },
            },
            styleOverrides: {
                root: {
                    marginLeft: '-12px',
                },
            },
        },
        MuiAutocomplete: {
            defaultProps: {
                autoComplete: true,
                blurOnSelect: true,
                clearOnBlur: false,
            },
        },
        MuiInputLabel: {
            defaultProps: {
                required: false,
                focused: false,
            },
            styleOverrides: {
                root: ({theme}) => ({
                    left: 1,
                    userSelect: 'text',
                    position: 'relative',
                    marginBottom: 5,
                    transform: 'translate(0)',
                    color: theme.palette.text.primary,
                    fontWeight: 500,
                    fontSize: '0.875rem',
                    lineHeight: '1.25rem',
                }),
            },
        },
        MuiTextField: {
            defaultProps: {
                autoComplete: 'off',
            },
            styleOverrides: {
                root: {
                    variants: [
                        {
                            props: {margin: 'normal'},
                            style: {
                                marginTop: 4,
                            },
                        },
                    ],
                    '.MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: ({theme}) => ({
                    borderRadius: '0.5rem',
                    backgroundColor: theme.palette.background.default,
                    boxShadow: '0 4px 40px rgba(0, 0, 0, .08)',
                }),
                input: ({theme}) => ({
                    '&:-webkit-autofill, &:autofill': {
                        boxShadow: '0 0 0 30px #fff inset !important',
                        WebkitTextFillColor: `${theme.palette.text.primary} !important`,
                    },
                    '::placeholder': {
                        opacity: 1,
                        color: theme.palette.text.primary,
                    },
                }),
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginTop: 6,
                },
            },
        },
        MuiSelect: {
            defaultProps: {
                color: 'white',
            },
            styleOverrides: {
                root: {
                    '.MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                    },
                },
            },
        },
        MuiButton: {
            defaultProps: {
                variant: 'contained',
                color: 'primary',
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    borderRadius: '0.5rem',
                    paddingLeft: '1.75rem',
                    paddingRight: '1.75rem',
                },
            },
        },
        MuiLoadingButton: {
            defaultProps: {
                variant: 'contained',
                color: 'primary',
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    borderRadius: '0.5rem',
                    paddingLeft: '1.75rem',
                    paddingRight: '1.75rem',
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: '0.75rem',
                }
            },
        },
        MuiPaper: {
            styleOverrides: {
                elevation1: {
                    boxShadow: '0 2px 40px rgba(0, 0, 0, .05)',
                },
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    variants: [
                        {
                            props: {invisible: false},
                            style: {
                                backgroundColor: 'rgba(44, 44, 44, .2)',
                                backdropFilter: 'blur(4px)',
                            },
                        },
                    ],
                },
            },
        },
        MuiDialog: {
            defaultProps: {
                fullWidth: true,
            },
            styleOverrides: {
                paper: {
                    boxShadow: 'none',
                    borderRadius: '0.75rem',
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    fontWeight: 500,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingTop: 12,
                    paddingRight: 12,
                    paddingBottom: 7,
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    borderBottom: 'none',
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    justifyContent: 'center',
                    paddingRight: 24,
                    paddingLeft: 24,
                    paddingBottom: 24,
                }
            },
        },
        MuiSkeleton: {
            defaultProps: {
                width: 24,
                height: 24,
                className: 'mx-auto',
            },
        },
        MuiTableCell: {
            defaultProps: {
                align: 'center',
            },
            styleOverrides: {
                root: ({theme}) => ({
                    color: theme.palette.primary.main,
                }),
                head: ({theme}) => ({
                    borderBottom: `3px solid ${theme.palette.primary.main}`,
                }),
            },
        },
        MuiTable: {
            defaultProps: {
                className: 'md:px-8 pt-7',
            },
        },
        MuiDivider: {
            styleOverrides: {
                wrapper: {
                    padding: 0,
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                list: {
                    paddingTop: 0,
                    paddingBottom: 0,
                },
                paper: {
                    boxShadow: 'none',
                    borderRadius: '0.25rem',
                    filter: 'drop-shadow(0px 4px 40px rgba(0, 0, 0, .08))',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    borderBottom: '1px solid rgba(0, 0, 0, .12)',
                    '&:last-child': {
                        borderBottom: 'none',
                    },
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    borderBottom: '1px solid rgba(0, 0, 0, .12)',
                    '&:last-child': {
                        borderBottom: 'none',
                    },
                }
            },
        },
    },
});

theme = responsiveFontSizes(theme);

root.render(
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <TitleProvider>
                <FirebaseProvider>
                    <AlertsProvider>
                        <CookiesProvider>
                            <AuthProvider>
                                <TreeProvider>
                                    <RouteList/>
                                </TreeProvider>
                            </AuthProvider>
                        </CookiesProvider>
                    </AlertsProvider>
                </FirebaseProvider>
            </TitleProvider>
        </ThemeProvider>
    </StyledEngineProvider>
);

reportWebVitals(console.log);