import {
    ConfirmDisassociateMonitor,
    CreateOrEditAsset,
    CreateOrEditFolder,
    MenuEditAsset,
    MenuNewAsset,
    RefreshedAt,
} from '../components';
import Grid from '@mui/material/Grid2';
import {TreeContext} from '../services';
import {useAuth, useTitle} from '../hooks';
import getItemIds from '../utils/getItemIds';
import {RichTreeView} from '@mui/x-tree-view';
import {StyledTreeItem} from '../components/tree';
import {useContext, useEffect, useState} from 'react';
import {Button, CircularProgress, Tooltip, useMediaQuery, useTheme} from '@mui/material';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';

function AssetsTree() {
    const theme = useTheme();
    const {workspace} = useAuth();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const {
        timeout,
        loadAssetTree,
        folder,
        manageFolder,
        setManageFolder,
        isConfiguring,
        setIsConfiguring,
        expanded,
        setExpanded
    } = useContext(TreeContext);
    const [loading, setLoading] = useState(false);
    const [allItems, setAllItems] = useState([]);
    const {setTitle} = useTitle();

    const handleExpandAll = () => setExpanded(expanded.length >= allItems.length ? [] : allItems);

    useEffect(() => setTitle('Árvore de Ativos'), [setTitle]);

    useEffect(() => setAllItems(getItemIds(folder?.data)), [folder]);

    useEffect(() => {
        if (folder?.data) {
            const now = new Date();
            const date = Date.parse(`${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()} ${folder.data.hour}`);

            const diff = Math.abs((now.getTime() - date) / 6e4);

            if (folder?.data[0]?.id !== workspace) {
                setLoading(true);
                loadAssetTree(true, () => setLoading(false));
            } else if (diff > 1) {
                setLoading(true);
                loadAssetTree(null, () => setLoading(false));
            }
        } else {
            setLoading(true);
            loadAssetTree(true, () => setLoading(false));
        }

        // eslint-disable-next-line
        return () => clearTimeout(timeout.current);
        // eslint-disable-next-line
    }, [workspace]);

    return (
        <>
            <Grid container size={12} className="self-start sm:justify-end justify-center mt-2">
                <Grid className="flex items-end">
                    <Tooltip title={expanded.length >= allItems.length ? 'Recolher' : 'Expandir'}>
                        <span>
                            <Button
                                variant="outlined"
                                onClick={handleExpandAll}
                                className="mr-2 sm:mr-3"
                                disabled={loading || allItems.length === 0}
                            >
                                {
                                    expanded.length >= allItems.length ?
                                        <KeyboardDoubleArrowUpIcon/> :
                                        <KeyboardDoubleArrowDownIcon/>
                                }
                            </Button>
                        </span>
                    </Tooltip>
                </Grid>
                <Grid className="flex items-end">
                    <Button
                        variant="outlined"
                        className="mr-2 sm:mr-3"
                        endIcon={isConfiguring ? <VisibilityIcon/> : <SettingsIcon/>}
                        onClick={() => setIsConfiguring(!isConfiguring)}
                        disabled={loading || allItems.length === 0}
                    >
                        {isConfiguring ? 'Visualizar' : 'Gerenciar'}
                    </Button>
                </Grid>
                <RefreshedAt
                    className="max-[407px]:my-2"
                    variant="contained"
                    size={null}
                    hour={folder.hour}
                    loading={loading}
                    refresh={() => {
                        setLoading(true);
                        loadAssetTree(null, () => setLoading(false));
                    }}
                />
            </Grid>
            <Grid container size={12} className="self-start mt-3">
                {
                    loading ? (
                        <CircularProgress className="m-auto"/>
                    ) : (
                        <RichTreeView
                            disableSelection
                            aria-label="Árvore de ativos"
                            className="w-full overflow-auto"
                            expandedItems={expanded}
                            onExpandedItemsChange={(event, itemIds) => setExpanded(itemIds)}
                            items={folder?.data ?? []}
                            getItemId={item => `${item.type}-${item.id}`}
                            getItemLabel={item => item.name}
                            slots={{
                                item: StyledTreeItem,
                                expandIcon: AddBoxOutlinedIcon,
                                collapseIcon: IndeterminateCheckBoxOutlinedIcon,
                                endIcon: () => <DisabledByDefaultOutlinedIcon className="opacity-30"/>,
                            }}
                        />
                    )
                }
            </Grid>
            <MenuNewAsset/>
            <MenuEditAsset/>
            <CreateOrEditAsset/>
            <ConfirmDisassociateMonitor callback={loadAssetTree}/>
            <CreateOrEditFolder
                folder={manageFolder}
                setFolder={setManageFolder}
                fullScreen={fullScreen}
                loadFolders={expand => {
                    setLoading(true);
                    loadAssetTree(expand, () => setLoading(false));
                }}
                type="pasta"
            />
        </>
    );
}

export default AssetsTree;

export {AssetsTree};