import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, InputAdornment,
    TextField,
    useMediaQuery,
    useTheme
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useContext, useEffect, useState} from 'react';
import {AlertContext, api, TreeContext} from '../services';
import useAuth from '../hooks/useAuth';
import BtnClose from './BtnClose';
import {getAssetAppearance} from '../utils';

function CreateOrEditAsset() {
    const theme = useTheme();
    const {handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.only('xs'));
    const {loadAssetTree, manageAsset, setManageAsset} = useContext(TreeContext);
    const [appearance, setAppearance] = useState({});

    const handleClose = () => setManageAsset({...manageAsset, open: false});

    const handleChange = event => {
        if (errors[event.target.id]) {
            setErrors({});
        }
    };

    function handleSubmit(event) {
        event.preventDefault();

        const name = event.target?.name?.value;

        if (name.length < 3) {
            setErrors({
                name: 'O nome precisa ter pelo menos 3 caracteres.',
            });
            return;
        }

        let apiMethod = api.post;
        let apiEndpoint = `/${manageAsset?.variant}s`;
        let apiData = {
            name,
            parent_id: manageAsset?.id,
            parent_type: manageAsset?.type,
        }

        if (manageAsset?.name) {
            apiMethod = api.put;
            apiEndpoint = `/${manageAsset?.type}s/${manageAsset?.id}`;
            apiData = {name};
        }

        setLoading(true);

        apiMethod(apiEndpoint, apiData)
            .then(response => {
                const expand = manageAsset?.name ? '' : `${manageAsset?.type}-${manageAsset?.id}`;

                handleClose()
                loadAssetTree(expand);
                newAlert(response?.data?.message, 'success');
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) setErrors(error.response?.data?.errors || {errors: {}});
                else {
                    const action = manageAsset?.name ? 'editar' : 'criar';

                    newAlert(error.response?.data?.message ?? `Erro ao tentar ${action} ${appearance?.prefix} ${appearance?.type}. Tente novamente mais tarde.`);
                }
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (!manageAsset?.open) return;

        setAppearance(getAssetAppearance(manageAsset?.variant ?? manageAsset?.type, true))
        // eslint-disable-next-line
    }, [manageAsset]);

    return (
        <Dialog
            maxWidth="xs"
            fullScreen={fullScreen}
            open={Boolean(manageAsset?.open)}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
            }}
        >
            <DialogTitle>
                <Box>
                    {manageAsset?.name ? 'Renomear' : `Nov${appearance?.prefix}`} {appearance?.type}
                </Box>
                <BtnClose onClick={handleClose}/>
            </DialogTitle>
            <DialogContent dividers>
                <Box className="flex flex-end items-end mb-3">
                    <TextField
                        required
                        autoFocus
                        fullWidth
                        name="name"
                        label={`Nome d${appearance?.prefix} ${appearance?.type}`}
                        defaultValue={manageAsset?.name ?? ''}
                        onChange={handleChange}
                        error={Boolean(errors?.name)}
                        helperText={errors?.name}
                        slotProps={{
                            input: {
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {
                                            appearance?.Icon && (
                                                <appearance.Icon sx={{color: 'action.active', mr: 1, my: 0.5}}/>
                                            )
                                        }
                                    </InputAdornment>
                                ),
                            },
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    fullWidth
                    type="submit"
                    loading={loading}
                    disabled={Boolean(errors?.name)}
                >
                    Salvar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default CreateOrEditAsset;

export {CreateOrEditAsset};