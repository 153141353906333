import {api} from '../services';
import {useState} from 'react';
import {LoadingButton} from '@mui/lab';
import Grid from '@mui/material/Grid2';
import {filterObject} from '../utils/filterObject';
import {BtnShowPassword, ForgotPassword} from './';
import {Checkbox, FormControlLabel, Link, TextField, Typography} from '@mui/material';

function PasswordChange({newAlert, handleLogout, email}) {
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false);
    const handleForgotPasswordClick = () => setForgotPassword(true);

    function handleChange(event) {
        if (errors[event.target.name]) {
            setErrors(filterObject(errors, index => index !== event.target.name));
        }
    }

    function handleSubmit(event) {
        event.preventDefault();

        setLoading(true);

        const current_password = event?.target?.current_password?.value;
        const new_password = event?.target?.new_password?.value;
        const new_password_confirmation = event?.target?.new_password_confirmation?.value;
        const disconnect = event?.target?.disconnect?.checked;

        if (new_password !== new_password_confirmation) {
            setErrors({
                new_password: 'As senhas não conferem.',
                new_password_confirmation: 'As senhas não conferem.',
            });
            setLoading(false);
            return;
        }

        if (current_password.length < 8) {
            setErrors({
                current_password: 'A senha precisa ter pelo menos 8 caracteres.',
            });
            setLoading(false);
            return;
        }

        if (new_password.length < 8) {
            setErrors({
                new_password: 'A nova senha precisa ter pelo menos 8 caracteres.',
                new_password_confirmation: 'A nova senha precisa ter pelo menos 8 caracteres.',
            });
            setLoading(false);
            return;
        }

        if (new_password === current_password) {
            setErrors({
                new_password: 'A nova senha não pode ser igual a senha atual.',
                new_password_confirmation: 'A nova senha não pode ser igual a senha atual.',
            });
            setLoading(false);
            return;
        }

        api
            .patch('/change-password', {
                current_password,
                new_password,
                new_password_confirmation,
                disconnect,
            })
            .then(response => {
                newAlert(response?.data?.message, 'success')
                event.target.reset();
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) setErrors(error.response?.data?.errors || {errors: {}});
                else {
                    newAlert(error.response?.data?.message ?? 'Erro ao tentar trocar senha. Tente novamente mais tarde.');
                }
            })
            .finally(() => setLoading(false));
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid
                    container
                    size={12}
                    className="p-2"
                    rowSpacing={{xs: 3, lg: 5}}
                >
                    <Grid container size={12} className="justify-between items-center mt-3" rowSpacing={0} columnSpacing={4}>
                        <Grid size={{xs: 12, lg: 6}} className="flex flex-col justify-center">
                            <Typography className="font-medium" component="label" htmlFor="currentPassword">
                                Senha
                            </Typography>
                            <Link
                                className="text-left cursor-pointer	w-36 mb-2 lg:mb-0"
                                underline="none"
                                variant="body2"
                                onClick={handleForgotPasswordClick}
                            >
                                Esqueceu a senha?
                            </Link>
                        </Grid>
                        <Grid size={{xs: 12, lg: 6}} className="flex items-center">
                            <TextField
                                required
                                fullWidth
                                name="current_password"
                                id="currentPassword"
                                placeholder="Senha atual"
                                margin="normal"
                                type={showPassword ? 'text' : 'password'}
                                onChange={handleChange}
                                error={Boolean(errors?.current_password)}
                                helperText={errors?.current_password}
                                autoComplete="current-password"
                                slotProps={{
                                    input: {
                                        endAdornment: <BtnShowPassword setShow={setShowPassword} show={showPassword}/>,
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container size={12} className="justify-between items-center" rowSpacing={0} columnSpacing={4}>
                        <Grid size={{xs: 12, lg: 6}} className="flex flex-col justify-center">
                            <Typography className="font-medium" component="label" htmlFor="newPassword">
                                Nova Senha
                            </Typography>
                            <Typography variant="body2" component="div" className="hidden lg:block">
                                Crie uma nova senha
                            </Typography>
                        </Grid>
                        <Grid size={{xs: 12, lg: 6}} className="flex flex-col justify-center items-end">
                            <TextField
                                required
                                fullWidth
                                name="new_password"
                                id="newPassword"
                                placeholder="Nova senha"
                                margin="normal"
                                type={showPassword ? 'text' : 'password'}
                                onChange={handleChange}
                                error={Boolean(errors?.new_password)}
                                helperText={errors?.new_password}
                                autoComplete="new-password"
                                slotProps={{
                                    input: {
                                        endAdornment: <BtnShowPassword setShow={setShowPassword} show={showPassword}/>,
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container size={12} className="justify-between items-center" rowSpacing={0} columnSpacing={4}>
                        <Grid size={{xs: 12, lg: 6}} className="flex flex-col justify-center">
                            <Typography className="font-medium" component="label" htmlFor="newPasswordConfirmation">
                                Confirmação
                            </Typography>
                            <Typography variant="body2" component="div" className="hidden lg:block">
                                Confirme a senha
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="disconnect"
                                        defaultChecked={true}
                                    />
                                }
                                label="Desconetar outros dispositivos"
                            />
                        </Grid>
                        <Grid size={{xs: 12, lg: 6}} className="flex flex-col justify-center items-end">
                            <TextField
                                required
                                fullWidth
                                name="new_password_confirmation"
                                id="newPasswordConfirmation"
                                placeholder="Confirmação"
                                margin="normal"
                                type={showPassword ? 'text' : 'password'}
                                onChange={handleChange}
                                error={Boolean(errors?.new_password_confirmation)}
                                helperText={errors?.new_password_confirmation}
                                autoComplete="new-password"
                                slotProps={{
                                    input: {
                                        endAdornment: <BtnShowPassword setShow={setShowPassword} show={showPassword}/>,
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className="justify-end p-2 mt-1">
                    <LoadingButton
                        type="submit"
                        loading={loading}
                        disabled={Object.keys(errors).length > 0}
                    >
                        Salvar
                    </LoadingButton>
                </Grid>
            </form>
            <ForgotPassword
                forgotPassword={forgotPassword}
                setForgotPassword={setForgotPassword}
                email={email}
                handleChange={() => null}
                errors={errors}
                setErrors={setErrors}
                disabled={true}
            />
        </>
    );
}

export default PasswordChange;

export {PasswordChange};
