import {createContext} from 'react';
import useAuthProvider from '../hooks/useAuthProvider';

const AuthContext = createContext({});

function AuthProvider({children}) {
    const {
        authenticated,
        loading,
        setUser,
        user,
        can,
        handleLogin,
        handleLogout,
        refreshUser,
        treeWorkspaces,
        workspaces,
        workspace,
        setWorkspace,
        selectWorkspace,
        setSelectWorkspace,
        loadingBackdrop,
        setLoadingBackdrop
    } = useAuthProvider();

    return (
        <AuthContext.Provider
            value={{
                authenticated,
                loading,
                setUser,
                user,
                can,
                handleLogin,
                handleLogout,
                refreshUser,
                treeWorkspaces,
                workspaces,
                workspace,
                setWorkspace,
                selectWorkspace,
                setSelectWorkspace,
                loadingBackdrop,
                setLoadingBackdrop
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export {AuthContext, AuthProvider};