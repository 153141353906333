import {
    ConfirmDeleteMonitor,
    CreateOrEditMonitor,
    FilterRecords,
    StyledTableCell,
    StyledTableRow,
    TablePagination,
    TableSortLabel,
} from '.';
import {
    Alert,
    Box,
    Button,
    Checkbox,
    IconButton,
    ListItem,
    ListItemButton,
    Menu,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Tooltip,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import env from 'react-dotenv';
import {useAuth} from '../hooks';
import {Link} from 'react-router-dom';
import {AlertContext, api} from '../services';
import {useContext, useEffect, useRef, useState} from 'react';
import levelDisplayStatuses from '../dictonarys/levelDisplayStatuses';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CircleIcon from '@mui/icons-material/Circle';
import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';

function TableLevelDisplays({manage = false}) {
    const theme = useTheme();
    const {workspace, handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [levelDisplays, setLevelDisplays] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [filters, setFilters] = useState(['normal', 'low']);
    const [showConfirmDelete, setShowConfirmDelete] = useState({open: false});
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [levelDisplay, setLevelDisplay] = useState({open: false});
    const [query, setQuery] = useState('');
    const interval = parseInt(localStorage.getItem('refresh') ?? env.REFRESH_DEFAULT_INTERVAL)  * 60 * 1000;
    let timeout = useRef(null);

    const handleMenu = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const confirmDelete = id => setShowConfirmDelete({open: true, id});

    function handleChange(type) {
        if (filters.length === 1 && filters[0] === type) return;

        if (filters.includes(type)) setFilters(filters.filter(filter => filter !== type));
        else setFilters([...filters, type]);
    }

    function loadLevelDisplays() {
        clearTimeout(timeout.current);
        setLoading(true);

        let url = '/level-displays';
        let params = {
            page: page + 1,
            per_page: perPage,
            order,
            order_by: orderBy,
            filters,
            query,
        }

        if (manage) url += '/all';
        else params['workspace'] = workspace;

        api
            .get(url, {params})
            .then(response => {
                setLevelDisplays(response.data);
                timeout.current = setTimeout(loadLevelDisplays, interval);
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else {
                    setLevelDisplays({});
                    newAlert(error.response?.data?.message ?? 'Erro ao tentar carregar visores de nível. Tente novamente mais tarde.');
                }
            })
            .finally(() => setLoading(false));
    }


    useEffect(() => {
        loadLevelDisplays();

        return () => clearTimeout(timeout.current);
        // eslint-disable-next-line
    }, [page, perPage, workspace, order, orderBy, filters, query]);

    return (
        <>
            <Box className="w-full">
                <FilterRecords
                    setQuery={setQuery}
                    setPage={setPage}
                    loading={loading}
                    onFilter={handleMenu}
                    refresh={loadLevelDisplays}
                    variant={manage ? 'outlined' : 'contained'}
                    placeholder="Pesquisar por id, serial ou local de instalação ..."
                />
                <Menu
                    id="filter-menu"
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                >
                    {
                        Object.values(levelDisplayStatuses).map(({type, text, color}, i) => (
                            <ListItem disablePadding key={i}>
                                <ListItemButton
                                    dense
                                    id={`filter-level-displays-${type}`}
                                    className="flex items-center justify-start"
                                    onClick={() => handleChange(type)}
                                >
                                    <Checkbox
                                        edge="start"
                                        checked={filters.indexOf(type) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        disabled={filters.length === 1 && filters[0] === type}
                                        slotProps={{
                                            input: {
                                                'aria-labelledby': `filter-level-displays-${type}`,
                                            },
                                        }}
                                    />
                                    <CircleIcon sx={{color}} className="ml-[5px] mr-4"/>
                                    {text}
                                </ListItemButton>
                            </ListItem>
                        ))
                    }
                </Menu>
                <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="Visores de Nível" sx={{minWidth: manage ? 1000 : 950}} size="small">
                        <TableHead>
                            <TableRow>
                                {
                                    [
                                        {label: 'id', text: 'ID', short: true},
                                        {label: 'serial', text: 'Serial', short: true},
                                        {label: 'displayable_type', text: 'Instalado na(o)', short: true},
                                        {label: 'alert_type', text: 'Status', short: false},
                                        {label: 'actions', text: 'Ações', short: false},
                                    ].map(({label, text, short}) => (
                                        <TableSortLabel
                                            key={label}
                                            label={label}
                                            text={text}
                                            short={short}
                                            order={order}
                                            setOrder={setOrder}
                                            orderBy={orderBy}
                                            setOrderBy={setOrderBy}
                                        />
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                loading ? (
                                        new Array(perPage).fill(1).map((value, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell>
                                                    <Skeleton/>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Skeleton width={250}/>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Skeleton width={200}/>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Skeleton/>
                                                </StyledTableCell>
                                                <StyledTableCell className="flex justify-center">
                                                    <Skeleton className="mx-2"/>
                                                    {
                                                        manage && (
                                                            <>
                                                                <Skeleton className="mx-2"/>
                                                                <Skeleton className="mx-2"/>
                                                            </>
                                                        )
                                                    }
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    ) :
                                    (
                                        (levelDisplays && levelDisplays?.data?.map(levelDisplay => {
                                            const Icon = levelDisplay?.displayable_type === 'component' ?
                                                RadioButtonCheckedIcon :
                                                levelDisplay?.displayable_type === 'subset' ?
                                                    SettingsIcon :
                                                    PrecisionManufacturingIcon;

                                            const status = levelDisplay?.status === null ?
                                                'text.secondary' :
                                                levelDisplay.status ?
                                                    'success.main' :
                                                    'error.main';

                                            return (
                                                <StyledTableRow key={levelDisplay?.id}>
                                                    <StyledTableCell>
                                                        {levelDisplay?.id}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {levelDisplay?.serial}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Box className="flex items-center justify-center">
                                                            {
                                                                levelDisplay?.displayable_type ? (
                                                                    <>
                                                                        <Icon className="mr-1"/>
                                                                        {levelDisplay?.displayable?.name}
                                                                    </>
                                                                ) : 'Não instalado'
                                                            }
                                                        </Box>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Box color={status} component={CircleIcon}/>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Tooltip title="Detalhar">
                                                            <Link to={`/level-displays/${levelDisplay?.id}`}>
                                                                <IconButton
                                                                    color="primary"
                                                                    aria-label="Detalhar"
                                                                >
                                                                    <VisibilityIcon/>
                                                                </IconButton>
                                                            </Link>
                                                        </Tooltip>
                                                        {
                                                            manage && (
                                                                <>
                                                                    <Tooltip title="Editar">
                                                                        <IconButton
                                                                            color="warning"
                                                                            aria-label="Editar"
                                                                            onClick={() => {
                                                                                setLevelDisplay({
                                                                                    open: true,
                                                                                    id: levelDisplay?.id,
                                                                                    serial: levelDisplay?.serial,
                                                                                })
                                                                            }}
                                                                        >
                                                                            <EditIcon/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Apagar">
                                                                        <IconButton
                                                                            color="error"
                                                                            aria-label="Apagar"
                                                                            onClick={() => confirmDelete(levelDisplay?.id)}
                                                                        >
                                                                            <DeleteIcon/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </>
                                                            )
                                                        }
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )
                                        }))
                                    )
                            }
                            {
                                !loading && !levelDisplays?.data?.length && (
                                    <TableRow>
                                        <TableCell colSpan={5} align="center">
                                            <Alert severity="warning">
                                                Nenhum visor de nível encontrado para os filtros informados.
                                            </Alert>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={5}
                                    rowsPerPage={perPage}
                                    setPerPage={setPerPage}
                                    page={page}
                                    setPage={setPage}
                                    count={levelDisplays?.meta?.total ?? 0}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                {
                    manage && (
                        <Box className="text-end w-full mt-8">
                            <Button onClick={() => setLevelDisplay({open: true})}>
                                Novo Visor de Nível
                            </Button>
                        </Box>
                    )
                }
            </Box>
            {
                manage && (
                    <>
                        <ConfirmDeleteMonitor
                            type="level-display"
                            showConfirmDelete={showConfirmDelete}
                            onClose={() => setShowConfirmDelete({...showConfirmDelete, open: false})}
                            fullScreen={fullScreen}
                            setMonitors={setLevelDisplays}
                        />
                        <CreateOrEditMonitor
                            type="level-display"
                            monitor={levelDisplay}
                            onClose={() => setLevelDisplay({...levelDisplay, open: false})}
                            fullScreen={fullScreen}
                            loadMonitors={loadLevelDisplays}
                        />
                    </>
                )
            }
        </>
    );
}

export default TableLevelDisplays;

export {TableLevelDisplays};