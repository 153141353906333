import {
    BtnClose,
    CreateOrEditUser,
    FilterRecords,
    ListFolderRole,
    StyledTableCell,
    StyledTableRow,
    TablePagination,
    TableSortLabel,
} from '../components';
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Link,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Tooltip,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import env from 'react-dotenv';
import {LoadingButton} from '@mui/lab';
import {useAuth, useTitle} from '../hooks';
import {phoneMask} from '../utils/phoneMask';
import {AlertContext, api} from '../services';
import {useContext, useEffect, useRef, useState} from 'react';
import EditIcon from '@mui/icons-material/EditOutlined';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlined';

function ListUsers() {
    const {setTitle} = useTitle();
    const theme = useTheme();
    const {user, handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [users, setUsers] = useState({});
    const [loading, setLoading] = useState(true);
    const [deleting, setDeleting] = useState(false);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [query, setQuery] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [showConfirmDelete, setShowConfirmDelete] = useState({open: false});
    const [showFolders, setShowFolders] = useState({open: false});
    const [manageUser, setManageUser] = useState({open: false, id: null})
    const interval = parseInt(localStorage.getItem('refresh') ?? env.REFRESH_DEFAULT_INTERVAL)  * 60 * 1000;
    let timeout = useRef(null);

    const handleClose = () => setShowConfirmDelete({...showConfirmDelete, open: false})

    function confirmDelete(id, name) {
        if (id === user?.id) {
            newAlert('Você não pode excluir seu próprio usuário.');
            return;
        }

        setShowConfirmDelete({open: true, id, name});
    }

    function deleteUser() {
        setDeleting(true);

        api
            .delete(`/users/${showConfirmDelete?.id}`)
            .then(response => {
                newAlert(response?.data?.message, 'success');
                setUsers(users => {
                    const newUsers = {...users};

                    newUsers.data = newUsers.data.filter(user => user.id !== showConfirmDelete?.id);
                    newUsers.meta.total--;

                    return newUsers;
                });
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else newAlert(error.response?.data?.message ?? 'Erro ao tentar excluir o usuário. Tente novamente mais tarde.');
            })
            .finally(() => {
                setDeleting(false);
                handleClose();
            });
    }

    function loadUsers() {
        clearTimeout(timeout.current);
        setLoading(true);

        api
            .get('/users', {
                params: {
                    page: page + 1,
                    per_page: perPage,
                    order,
                    order_by: orderBy,
                    query,
                }
            })
            .then(response => {
                setUsers(response.data);
                timeout.current = setTimeout(loadUsers, interval);
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else {
                    setUsers({});
                    newAlert(error.response?.data?.message ?? 'Erro ao tentar carregar usuários. Tente novamente mais tarde.');
                }
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => setTitle('Usuários'), [setTitle]);

    useEffect( () => {
        loadUsers();

        return () => clearTimeout(timeout.current);
        // eslint-disable-next-line
}, [page, perPage, query, order, orderBy]);

    return (
        <>
            <Box className="w-full">
                <FilterRecords
                    setQuery={setQuery}
                    setPage={setPage}
                    loading={loading}
                    refresh={loadUsers}
                    placeholder="Pesquisar por id, nome ou e-mail..."
                />
                <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="Usuários" sx={{minWidth: 1050}} size="small">
                        <TableHead>
                            <TableRow>
                                {
                                    [
                                        {label: 'id', text: 'ID', short: true},
                                        {label: 'name', text: 'Nome', short: true},
                                        {label: 'email', text: 'E-mail', short: true},
                                        {label: 'phone', text: 'Telefone', short: true},
                                        {label: 'is_admin', text: 'Administrador', short: true},
                                        {label: 'folders_count', text: 'Pastas', short: true},
                                        {label: 'actions', text: 'Ações', short: false},
                                    ].map(({label, text, short}) => (
                                        <TableSortLabel
                                            key={label}
                                            label={label}
                                            text={text}
                                            short={short}
                                            order={order}
                                            setOrder={setOrder}
                                            orderBy={orderBy}
                                            setOrderBy={setOrderBy}
                                        />
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                loading ? (
                                        new Array(perPage).fill(1).map((value, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell>
                                                    <Skeleton/>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Skeleton width={150}/>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Skeleton width={175}/>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Skeleton width={125}/>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Skeleton width={40}/>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Skeleton width={65}/>
                                                </StyledTableCell>
                                                <StyledTableCell className="flex justify-center">
                                                    <Skeleton className="mx-2"/>
                                                    <Skeleton className="mx-2"/>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    ) :
                                    (
                                        (users && users?.data?.map(user => (
                                            <StyledTableRow key={user?.id}>
                                                <StyledTableCell>
                                                    {user?.id}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {user?.name}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Box className="flex items-center justify-center">
                                                        <Tooltip
                                                            title={
                                                                user.email_notifications ?
                                                                    'Aceitou receber notificações' :
                                                                    'Não aceitou receber notificações'
                                                            }
                                                        >
                                                            {
                                                                user.email_notifications ?
                                                                    <CheckCircleIcon className="mr-1"
                                                                                     sx={{color: 'success.main'}}/> :
                                                                    <CancelIcon className="mr-1"
                                                                                sx={{color: 'error.main'}}/>
                                                            }
                                                        </Tooltip>
                                                        {user?.email}
                                                    </Box>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {user?.phone ? (
                                                        <Box className="flex items-center justify-center">
                                                            <Tooltip
                                                                title={
                                                                    user.phone_notifications ?
                                                                        'Aceitou receber notificações' :
                                                                        'Não aceitou receber notificações'
                                                                }
                                                            >
                                                                {
                                                                    user.phone_notifications ?
                                                                        <CheckCircleIcon className="mr-1"
                                                                                         sx={{color: 'success.main'}}/> :
                                                                        <CancelIcon className="mr-1"
                                                                                    sx={{color: 'error.main'}}/>
                                                                }
                                                            </Tooltip>
                                                            {phoneMask(user.phone)}
                                                        </Box>
                                                    ) : '-'}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {user?.is_admin ? 'Sim' : 'Não'}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {
                                                        user?.is_admin ?
                                                            'Todas' :
                                                            (
                                                                <Link
                                                                    underline="none"
                                                                    component="button"
                                                                    onClick={() => {
                                                                        setShowFolders({
                                                                            open: true,
                                                                            id: user?.id,
                                                                            name: user?.name
                                                                        });
                                                                    }}
                                                                >
                                                                    {user?.folders_count} pasta{user?.folders_count !== 1 && 's'}
                                                                </Link>
                                                            )
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Tooltip title="Editar">
                                                        <IconButton
                                                            color="warning"
                                                            aria-label="Editar"
                                                            onClick={() => setManageUser({open: true, id: user?.id})}
                                                        >
                                                            <EditIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Apagar">
                                                        <IconButton
                                                            color="error"
                                                            aria-label="Apagar"
                                                            onClick={() => confirmDelete(user?.id, user?.name)}
                                                        >
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )))
                                    )
                            }
                            {
                                !loading && !users?.data?.length && (
                                    <TableRow>
                                        <TableCell colSpan={7} align="center">
                                            <Alert severity="warning">
                                                Nenhum usuário encontrado para os dados informados.
                                            </Alert>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={7}
                                    rowsPerPage={perPage}
                                    setPerPage={setPerPage}
                                    page={page}
                                    setPage={setPage}
                                    count={users?.meta?.total ?? 0}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                <Box className="text-end w-full mt-8">
                    <Button onClick={() => setManageUser({open: true, id: null})}>
                        Novo Usuário
                    </Button>
                </Box>
            </Box>
            <CreateOrEditUser
                loadUsers={loadUsers}
                manageUser={manageUser}
                onClose={() => setManageUser({...manageUser, open: false})}
            />
            <Dialog
                maxWidth="sm"
                open={Boolean(showConfirmDelete?.open)}
                onClose={handleClose}
                fullScreen={fullScreen}
            >
                <DialogTitle>
                    <Box>
                        Excluir o usuário {showConfirmDelete?.name}?
                    </Box>
                    <BtnClose onClick={handleClose}/>
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        A exclusão desse usuário removerá todos os dados associados a ele.
                    </DialogContentText>
                    <div className="text-center font-bold mt-3">
                        ESTA AÇÃO NÃO PODERÁ SER DESFEITA!
                    </div>
                </DialogContent>
                <DialogActions className="justify-end">
                    <Button
                        autoFocus
                        variant="outlined"
                        onClick={handleClose}
                    >
                        Cancelar
                    </Button>
                    <LoadingButton
                        color="error"
                        loading={deleting}
                        onClick={deleteUser}
                    >
                        Deletar
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <ListFolderRole
                showFolders={showFolders}
                setShowFolders={setShowFolders}
            />
        </>
    );
}

export default ListUsers;

export {ListUsers};