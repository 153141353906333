import env from 'react-dotenv';
import Grid from '@mui/material/Grid2';
import {AlertContext} from '../services';
import {useAuth, useTitle} from '../hooks';
import {useContext, useEffect, useState} from 'react';
import {Autocomplete, Divider, TextField, Typography,} from '@mui/material';
import {ActiveSessions, PasswordChange, UpdateAccount} from '../components';

function MyAccount() {
    const {setTitle} = useTitle();
    const {handleLogout, user} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const times = [
        {
            label: '10 minutos',
            value: 10,
        },
        {
            label: '15 minutos',
            value: 15,
        },
        {
            label: '30 minutos',
            value: 30,
        },
        {
            label: '1 hora',
            value: 60,
        },
        {
            label: '1 hora e 30 minutos',
            value: 90,
        },
        {
            label: '2 horas',
            value: 120,
        },
    ];
    const value = localStorage.getItem('refresh');
    const [refresh, setRefresh] = useState(times.filter(e => e.value === parseInt(value ?? env.REFRESH_DEFAULT_INTERVAL))[0]);

    function handleChange(event, newValue) {
        setRefresh(newValue);
        localStorage.setItem('refresh', newValue.value);
    }

    useEffect(() => setTitle('Minha conta'), [setTitle]);

    return (
        <Grid
            container
            size={12}
            className="self-start justify-center"
            spacing={2}
            direction="row"
        >
            <Grid size={12}>
                <Grid size={12}>
                    <Typography variant="h5" className="mt-3">
                        Informações da conta
                    </Typography>
                    <Divider className="mt-2"/>
                </Grid>
                <UpdateAccount newAlert={newAlert}/>
            </Grid>
            <Grid size={12}>
                <Grid size={12}>
                    <Typography variant="h5" className="mt-3">
                        Dados de Acesso
                    </Typography>
                    <Divider className="mt-2"/>
                </Grid>
                <PasswordChange
                    newAlert={newAlert}
                    handleLogout={handleLogout}
                    email={user.email}
                />
            </Grid>
            <Grid size={12}>
                <Grid size={12}>
                    <Typography variant="h5" className="mt-3">
                        Configuração de Atualização
                    </Typography>
                    <Divider className="mt-2"/>
                </Grid>
                <form onSubmit={e => e.preventDefault()}>
                    <Grid
                        container
                        size={12}
                        className="p-2"
                        rowSpacing={{xs: 3, lg: 5}}
                    >
                        <Grid container size={12} className="justify-between items-center mt-3" rowSpacing={0}
                              columnSpacing={4}>
                            <Grid size={{xs: 12, lg: 6}} className="flex items-center">
                                <Typography component="label" htmlFor="name" variant="body2">
                                    Defina o intervalo de tempo para que o sistema seja atualizado automaticamente.
                                </Typography>
                            </Grid>
                            <Grid size={{xs: 12, lg: 6}} className="flex items-center">
                                <Autocomplete
                                    fullWidth
                                    value={refresh}
                                    options={times}
                                    onChange={handleChange}
                                    noOptionsText="Nenhuma opção"
                                    getOptionLabel={option => 'Atualizar a cada ' + option.label}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            name="refresh"
                                            margin="normal"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
            <Grid size={12}>
                <Grid size={12}>
                    <Typography variant="h5" className="mt-3">
                        Sessões ativas
                    </Typography>
                    <Divider className="mt-2"/>
                </Grid>
                <ActiveSessions/>
            </Grid>
        </Grid>
    );
}

export default MyAccount;

export {MyAccount};