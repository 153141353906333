import {
    Box,
    Dialog,
    DialogActions,
    DialogContent, DialogTitle,
    TextField,
} from '@mui/material';
import {BtnClose} from './';
import {useAuth} from '../hooks';
import Grid from '@mui/material/Grid2';
import {LoadingButton} from '@mui/lab';
import {AlertContext, api} from '../services';
import {filterObject} from '../utils/filterObject';
import {useContext, useEffect, useState} from 'react';

function CreateOrEditRole({role, onClose, fullScreen, loadRoles}) {
    const {handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [errors, setErrors] = useState({});
    const [name, setName] = useState();
    const [description, setDescription] = useState();
    const [loading, setLoading] = useState(false);

    function handleChange(event) {
        if (event.target.name === 'name') setName(event.target.value);
        else if (event.target.name === 'description') setDescription(event.target.value);

        if (errors[event.target.name]) {
            setErrors(filterObject(errors, index => index !== event.target.name));
        }
    }

    function handleSubmit(event) {
        event.preventDefault();

        let apiMethod = api.post;
        let apiEndpoint = '/roles';

        if (role?.data?.id) {
            apiMethod = api.put;
            apiEndpoint += '/' + role.data.id;
        }

        setLoading(true);

        apiMethod(apiEndpoint, {
            name,
            description,
        })
            .then(response => {
                onClose();
                newAlert(response?.data?.message, 'success');
                loadRoles();
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) setErrors(error.response?.data?.errors || {errors: {}});
                else {
                    const action = role?.data?.id ? 'editar' : 'criar';

                    newAlert(error.response?.data?.message ?? `Erro ao tentar ${action} a função. Tente novamente mais tarde.`);
                }
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (!role?.open) return;

        setName(role?.data?.name ?? '');
        setDescription(role?.data?.description ?? '');
        setErrors({});
        // eslint-disable-next-line
    }, [role]);

    return (
        <Dialog
            maxWidth="md"
            open={Boolean(role?.open)}
            onClose={onClose}
            fullScreen={fullScreen}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
            }}
        >
            <DialogTitle>
                <Box>
                    {role?.data?.id ? 'Editar' : 'Nova'} função
                </Box>
                <BtnClose onClick={onClose}/>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container columnSpacing={7}>
                    <Grid size={{xs: 12, sm: 6}}>
                        <TextField
                            required
                            autoFocus
                            fullWidth
                            name="name"
                            label="Nome"
                            margin="normal"
                            type="text"
                            value={name}
                            onChange={handleChange}
                            error={Boolean(errors?.name)}
                            helperText={errors?.name}
                        />
                    </Grid>
                    <Grid size={{xs: 12, sm: 6}}>
                        <TextField
                            fullWidth
                            name="description"
                            label="Descrição"
                            margin="normal"
                            type="text"
                            value={description}
                            onChange={handleChange}
                            error={Boolean(errors?.description)}
                            helperText={errors?.description}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    type="submit"
                    className="px-12"
                    loading={loading}
                    disabled={Object.keys(errors).length > 0}
                >
                    Salvar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default CreateOrEditRole;

export {CreateOrEditRole};