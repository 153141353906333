import {Checkbox, FormControlLabel, Link, TextField, Typography} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {LoadingButton} from '@mui/lab';
import {useState} from 'react';
import {api} from '../services';
import useAuth from '../hooks/useAuth';
import BtnShowPassword from './BtnShowPassword';
import {filterObject, phoneMask} from '../utils';

function UpdateAccount({newAlert}) {
    const {handleLogout, user, setUser, setLoadingBackdrop} = useAuth();
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [disableUpdateAccountBtn, setDisableUpdateAccountBtn] = useState(false);
    const [changeEmail, setChangeEmail] = useState(false);

    function handleChange(event) {
        const id = event.target.id === 'phone_notifications' ? 'phone' : event.target.id;

        if (errors[id]) {
            setErrors(filterObject(errors, index => index !== id));
        }
    }

    function handlePhone(event) {
        const input = event.target;

        input.value = phoneMask(input.value);
    }

    function handleCancelChange() {
        setLoadingBackdrop(true);

        api.patch('/email/cancel-change')
            .then(response => {
                setUser(user => {
                    let newUser = {...user};

                    newUser.new_email = null;

                    return newUser
                });

                newAlert(response?.data?.message, 'success');
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else {
                    newAlert(error.response?.data?.message ?? 'Erro ao tentar cancelar alteração. Tente novamente mais tarde.');
                }
            })
            .finally(setLoadingBackdrop(false));
    }

    function handleSubmit(event) {
        event.preventDefault();

        setDisableUpdateAccountBtn(true);

        const name = event?.target?.name?.value;
        const email = event?.target?.email?.value;
        const emailNotifications = event?.target?.email_notifications?.checked;
        const phone = event?.target?.phone?.value.replace(/[^0-9]/g, '');
        const phoneNotifications = event?.target?.phone_notifications?.checked;
        const password = event?.target?.password?.value;

        if (name.length < 3) {
            setErrors({
                name: 'O nome precisa ter pelo menos 3 caracteres.',
            });
            setDisableUpdateAccountBtn(false);
            return;
        }

        if (phone.length > 0 && phone.length < 11) {
            setErrors({
                phone: 'O telefone precisa ter 11 dígitos.',
            });
            setDisableUpdateAccountBtn(false);
            return;
        }

        if (phoneNotifications && !phone) {
            setErrors({
                phone: 'O telefone é obrigatório se deseja receber notificações.',
            });
            setDisableUpdateAccountBtn(false);
            return;
        }

        let data = {
            name,
            email_notifications: emailNotifications,
            phone,
            phone_notifications: phoneNotifications,
        };

        if (changeEmail) {
            data.email = email;
            data.password = password;
        }

        api.patch('/update-account', data)
            .then(response => {
                newAlert(response?.data?.message, 'success');

                setUser(user => {
                    let newUser = {...user};

                    newUser.name = name;
                    newUser.email_notifications = emailNotifications;
                    newUser.phone_notifications = phoneNotifications;
                    if (changeEmail) newUser.new_email = email;

                    return newUser
                });

                if (changeEmail) event.target.password.value = '';
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) setErrors(error.response?.data?.errors || {errors: {}});
                else {
                    newAlert(error.response?.data?.message ?? 'Erro ao tentar atualizar informações. Tente novamente mais tarde.');
                }
            })
            .finally(() => setDisableUpdateAccountBtn(false));
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid
                container
                size={12}
                className="p-2"
                rowSpacing={{xs: 3, lg: 5}}
            >
                <Grid container size={12} className="justify-between items-center mt-3" rowSpacing={0} columnSpacing={4}>
                    <Grid size={{xs: 12, lg: 6}} className="flex flex-col justify-center">
                        <Typography className="font-medium" component="label" htmlFor="name">
                            Nome
                        </Typography>
                        <Typography variant="body2" component="div" className="hidden lg:block">
                            Adicione seu nome
                        </Typography>
                    </Grid>
                    <Grid size={{xs: 12, lg: 6}} className="flex items-center">
                        <TextField
                            required
                            autoFocus
                            fullWidth
                            name="name"
                            id="name"
                            placeholder="Nome"
                            margin="normal"
                            type="text"
                            defaultValue={user?.name}
                            onChange={handleChange}
                            error={Boolean(errors?.name)}
                            helperText={errors?.name}
                            autoComplete="name"
                        />
                    </Grid>
                </Grid>
                <Grid container size={12} className="justify-between items-center" rowSpacing={0} columnSpacing={4}>
                    <Grid size={{xs: 12, lg: 6}} className="flex flex-col justify-center">
                        <Typography className="font-medium" component="label" htmlFor="email">
                            E-mail
                        </Typography>
                        <Typography variant="body2" component="div" className="hidden lg:block">
                            Adicione seu e-mail
                        </Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="email_notifications"
                                    defaultChecked={user?.email_notifications}
                                    onChange={handleChange}
                                />
                            }
                            label="Desejo receber notificações por e-mail"
                        />
                    </Grid>
                    <Grid size={{xs: 12, lg: 6}} className="flex flex-col justify-center items-end">
                        <TextField
                            fullWidth
                            name="email"
                            id="email"
                            placeholder="E-mail"
                            margin="normal"
                            type="email"
                            defaultValue={user?.email}
                            onChange={handleChange}
                            disabled={!changeEmail}
                            error={Boolean(errors?.email)}
                            helperText={
                                errors?.email ?? (
                                    user?.new_email ?
                                        (
                                            <>
                                                O e-mail {user?.new_email} está pendente de confirmação.&nbsp;
                                                <Link
                                                    color="error"
                                                    underline="none"
                                                    component="button"
                                                    type="button"
                                                    onClick={handleCancelChange}
                                                >
                                                    Cancelar alteração
                                                </Link>
                                            </>
                                        ) :
                                        null
                                )
                            }
                            autoComplete="username email"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="change-mail"
                                    checked={changeEmail}
                                    onChange={event => setChangeEmail(event.target.checked)}
                                />
                            }
                            label="Editar Email"
                        />
                    </Grid>
                </Grid>
                {
                    changeEmail && (
                        <Grid container size={12} className="justify-between items-center" rowSpacing={0} columnSpacing={4}>
                            <Grid size={{xs: 12, lg: 6}} className="flex flex-col justify-center">
                                <Typography className="font-medium" component="label" htmlFor="password">
                                    Senha
                                </Typography>
                                <Typography variant="body2" component="div" className="hidden lg:block">
                                    Confirme sua senha
                                </Typography>
                            </Grid>
                            <Grid size={{xs: 12, lg: 6}} className="flex flex-col justify-center items-end">
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    id="password"
                                    placeholder="Senha atual"
                                    margin="normal"
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={handleChange}
                                    error={Boolean(errors?.password)}
                                    helperText={errors?.password}
                                    autoComplete="current-password"
                                    slotProps={{
                                        input: {
                                            endAdornment: <BtnShowPassword setShow={setShowPassword} show={showPassword}/>,
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )
                }
                <Grid container size={12} className="justify-between items-center" rowSpacing={0} columnSpacing={4}>
                    <Grid size={{xs: 12, lg: 6}} className="flex flex-col justify-center">
                        <Typography className="font-medium" component="label" htmlFor="phone">
                            Telefone
                        </Typography>
                        <Typography variant="body2" component="div" className="hidden lg:block">
                            Adicione seu telefone
                        </Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="phone_notifications"
                                    defaultChecked={user?.phone_notifications}
                                    onChange={handleChange}
                                />
                            }
                            label="Desejo receber notificações por WhatsApp ou SMS"
                        />
                    </Grid>
                    <Grid size={{xs: 12, lg: 6}} className="flex flex-col justify-center items-end">
                        <TextField
                            fullWidth
                            name="phone"
                            id="phone"
                            placeholder="Telefone"
                            margin="normal"
                            type="tel"
                            autoComplete="tel-national"
                            defaultValue={phoneMask(user?.phone)}
                            onChange={handleChange}
                            error={Boolean(errors?.phone)}
                            helperText={errors?.phone}
                            onKeyUp={handlePhone}
                            slotProps={{
                                htmlInput: {
                                    maxLength: 15,
                                },
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className="justify-end p-2 mt-1">
                <LoadingButton
                    type="submit"
                    loading={disableUpdateAccountBtn}
                    disabled={Object.keys(errors).length > 0}
                >
                    Salvar
                </LoadingButton>
            </Grid>
        </form>
    );
}

export default UpdateAccount;

export {UpdateAccount};