import {
    Autocomplete,
    Box,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    TextField,
    Typography
} from '@mui/material';
import {BtnClose} from './index';
import {useAuth} from '../hooks';
import Grid from '@mui/material/Grid2';
import {LoadingButton} from '@mui/lab';
import {AlertContext, api} from '../services';
import {useContext, useEffect, useState} from 'react';
import {cepMask, cnpjMask, filterObject, uppercaseFirst} from '../utils';

function CreateOrEditFolder({folder, setFolder, fullScreen, loadFolders, type}) {
    const {newAlert} = useContext(AlertContext);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const {can, handleLogout, setLoadingBackdrop} = useAuth();
    const [id, setId] = useState(0);
    const [name, setName] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [unit, setUnit] = useState('');
    const [street, setStreet] = useState('');
    const [number, setNumber] = useState('');
    const [complement, setComplement] = useState('');
    const [neighborhood, setNeighborhood] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState(null);
    const [cep, setCep] = useState('');
    const [companyName, setCompanyName] = useState('');
    const actions = {
        create: 'Adicionar',
        edit: 'Salvar',
        show: 'Editar',
        duplicate: 'Duplicar',
    };

    const OPTIONS = [
        {
            label: 'São Paulo',
            code: 'SP',
        },
        {
            label: 'Rio de Janeiro',
            code: 'RJ',
        },
        {
            label: 'Espírito Santo',
            code: 'ES',
        },
        {
            label: 'Minas Gerais',
            code: 'MG',
        },
        {
            label: 'Bahia',
            code: 'BA',
        },
        {
            label: 'Distrito Federal',
            code: 'DF',
        },
        {
            label: 'Paraná',
            code: 'PR',
        },
        {
            label: 'Rio Grande do Sul',
            code: 'RS',
        },
        {
            label: 'Pernambuco',
            code: 'PE',
        },
        {
            label: 'Ceará',
            code: 'CE',
        },
        {
            label: 'Pará',
            code: 'PA',
        },
        {
            label: 'Santa Catarina',
            code: 'SC',
        },
        {
            label: 'Goáis',
            code: 'GO',
        },
        {
            label: 'Maranhão',
            code: 'MA',
        },
        {
            label: 'Paraíba',
            code: 'PB',
        },
        {
            label: 'Amazonas',
            code: 'AM'
        },
        {
            label: 'Mato Grosso',
            code: 'MT',
        },
        {
            label: 'Rio Grande do Norte',
            code: 'RN',
        },
        {
            label: 'Piauí',
            code: 'PI',
        },
        {
            label: 'Alagoas',
            code: 'AL',
        },
        {
            label: 'Mato Grosso do Sul',
            code: 'MS',
        },
        {
            label: 'Sergipe',
            code: 'SE',
        },
        {
            label: 'Rondônia',
            code: 'RO',
        },
        {
            label: 'Tocantins',
            code: 'TO',
        },
        {
            label: 'Acre',
            code: 'AC',
        },
        {
            label: 'Amapá',
            code: 'AP',
        },
        {
            label: 'Roraima',
            code: 'RR'
        },
    ];

    const handleClose = () => setFolder({...folder, open: false});

    function reset() {
        if (folder?.id === id) return;

        setId('');
        setName('');
        setCnpj('');
        setCompanyName('');
        setUnit('');
        setStreet('');
        setNumber('');
        setComplement('');
        setNeighborhood('');
        setCity('');
        setState(null);
        setCep('');
        setErrors({});
    }

    function handleCnpj(event) {
        const input = event.target;

        input.value = cnpjMask(input.value);
    }

    function handleCep(event) {
        const input = event.target;

        input.value = cepMask(input.value);
    }

    function handleChange(event, newValue) {
        const name = event.target.name ?? 'state';
        const value = event.target.value;

        if (name === 'name') setName(value);
        else if (name === 'cnpj') setCnpj(value);
        else if (name === 'company_name') setCompanyName(value);
        else if (name === 'unit') setUnit(value);
        else if (name === 'street') setStreet(value);
        else if (name === 'number') setNumber(value);
        else if (name === 'complement') setComplement(value);
        else if (name === 'neighborhood') setNeighborhood(value);
        else if (name === 'city') setCity(value);
        else if (name === 'state') setState(newValue);
        else if (name === 'cep') setCep(value);

        if (errors[name]) {
            setErrors(filterObject(errors, index => index !== name));
        }
    }

    function handleSubmit(event) {
        event.preventDefault();

        if (folder?.action === 'show') {
            setFolder({...folder, action: 'edit'});
            return;
        }

        const cepNumeric = cep.replace(/[^0-9]/g, '');
        const cnpjNumeric = cnpj.replace(/[^0-9]/g, '');

        if (name.length < 3) {
            setErrors({
                name: 'O nome precisa ter pelo menos 3 caracteres.',
            });
            return;
        }

        if (cepNumeric.length > 0 && cepNumeric.length < 8) {
            setErrors({
                cep: 'O cep precisa ter 8 dígitos.',
            });
            return;
        }

        if (cnpjNumeric.length > 0 && cnpjNumeric.length < 14) {
            setErrors({
                cnpj: 'O cnpj precisa ter 14 dígitos.',
            });
            return;
        }

        let apiMethod = api.post;
        let apiEndpoint = '/folders';
        let data = {
            name,
            cnpj: cnpjNumeric,
            company_name: companyName,
            unit,
            street,
            number,
            complement,
            neighborhood,
            city,
            state: state.code,
            cep: cepNumeric,
        };

        if (type === 'empresa') {
            apiEndpoint += '/root';
        } else if (folder?.action === 'create') {
            data.parent_type = 'folder';
            data.parent_id = folder?.parent;
        }

        if (folder?.id) {
            apiEndpoint += '/' + folder.id;

            if (folder?.action !== 'duplicate') {
                apiMethod = api.put;
            } else {
                apiEndpoint += '/duplicate';
            }
        }

        setLoading(true);

        apiMethod(apiEndpoint, data)
            .then(response => {
                const expand = folder?.parent ? `folder-${folder?.parent}` : '';

                handleClose();
                loadFolders(expand);
                newAlert(response?.data?.message, 'success');
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) setErrors(error.response?.data?.errors || {errors: {}});
                else {
                    const action = folder?.id ? 'editar' : 'criar';

                    newAlert(error.response?.data?.message ?? `Erro ao tentar ${action} a ${type}. Tente novamente mais tarde.`);
                }
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (!folder.open) return;

        reset();

        if (!folder?.id || folder?.action === 'create' || folder?.id === id) return;

        setLoadingBackdrop(true);

        api.get('/folders/' + folder?.id)
            .then(response => {
                response = response.data?.data;

                setId(response?.id ?? '');
                setName(response?.name ?? '');
                setCnpj(response?.cnpj ?? '');
                setCompanyName(response?.company_name ?? '');
                setUnit(response?.unit ?? '');
                setStreet(response?.street ?? '');
                setNumber(response?.number ?? '');
                setComplement(response?.complement ?? '');
                setNeighborhood(response?.neighborhood ?? '');
                setCity(response?.city ?? '');
                setState(response?.state ?? '');
                setCep(response?.cep ?? '');
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else {
                    newAlert(error.response?.data?.message ?? `Erro ao tentar carregar a ${type}. Tente novamente mais tarde.`);
                }
            })
            .finally(() => setLoadingBackdrop(false));

        // eslint-disable-next-line
    }, [folder]);

    return (
        <Dialog
            maxWidth="md"
            open={Boolean(folder?.open)}
            onClose={handleClose}
            fullScreen={fullScreen}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
                noValidate: true,
            }}
        >
            <DialogTitle>
                <Box>
                    Dados da {uppercaseFirst(type)}
                </Box>
                <BtnClose onClick={handleClose}/>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={3} rowSpacing={0}>
                    <Grid container size={{xs: 12, md: 6}} rowSpacing={0}>
                        <TextField
                            required
                            fullWidth
                            name="name"
                            label="Nome"
                            margin="normal"
                            type="text"
                            value={name}
                            onChange={handleChange}
                            autoFocus={folder?.action !== 'show'}
                            error={Boolean(errors?.name)}
                            helperText={errors?.name}
                            slotProps={{
                                input: {
                                    readOnly: folder?.action === 'show',
                                },
                            }}
                        />
                    </Grid>
                    <Grid size={{xs: 12, md: 6}}>
                        <TextField
                            fullWidth
                            name="cnpj"
                            label="CNPJ"
                            margin="normal"
                            type="text"
                            value={cnpjMask(cnpj)}
                            onChange={handleChange}
                            onKeyUp={handleCnpj}
                            error={Boolean(errors?.cnpj)}
                            helperText={errors?.cnpj}
                            slotProps={{
                                htmlInput: {
                                    maxLength: 18,
                                },
                                input: {
                                    readOnly: folder?.action === 'show',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <TextField
                    fullWidth
                    name="company_name"
                    label="Razão Social"
                    margin="normal"
                    type="text"
                    value={companyName}
                    onChange={handleChange}
                    error={Boolean(errors?.company_name)}
                    helperText={errors?.company_name}
                    slotProps={{
                        input: {
                            readOnly: folder?.action === 'show',
                        },
                    }}
                />
                <TextField
                    fullWidth
                    name="unit"
                    label="Unidade"
                    margin="normal"
                    type="text"
                    value={unit}
                    onChange={handleChange}
                    error={Boolean(errors?.unit)}
                    helperText={errors?.unit}
                    slotProps={{
                        input: {
                            readOnly: folder?.action === 'show',
                        },
                    }}
                />

                <Divider className="my-3" textAlign="left">
                    <Typography className="font-medium" variant="body2">
                        Endereço
                    </Typography>
                </Divider>
                <Grid container spacing={3} rowSpacing={0}>
                    <Grid size={{xs: 12, md: 10}}>
                        <TextField
                            fullWidth
                            name="street"
                            label="Rua"
                            margin="normal"
                            type="text"
                            value={street}
                            onChange={handleChange}
                            error={Boolean(errors?.street)}
                            helperText={errors?.street}
                            slotProps={{
                                input: {
                                    readOnly: folder?.action === 'show',
                                },
                            }}
                        />
                    </Grid>
                    <Grid size={{xs: 12, md: 2}}>
                        <TextField
                            fullWidth
                            name="number"
                            label="Número"
                            margin="normal"
                            type="text"
                            value={number}
                            onChange={handleChange}
                            error={Boolean(errors?.number)}
                            helperText={errors?.number}
                            slotProps={{
                                input: {
                                    readOnly: folder?.action === 'show',
                                },
                            }}
                        />
                    </Grid>
                    <Grid size={{xs: 12, md: 6}}>
                        <TextField
                            fullWidth
                            name="neighborhood"
                            label="Bairro"
                            margin="normal"
                            type="text"
                            value={neighborhood}
                            onChange={handleChange}
                            error={Boolean(errors?.neighborhood)}
                            helperText={errors?.neighborhood}
                            slotProps={{
                                input: {
                                    readOnly: folder?.action === 'show',
                                },
                            }}
                        />
                    </Grid>
                    <Grid size={{xs: 12, md: 6}}>
                        <TextField
                            fullWidth
                            name="complement"
                            label="Complemento"
                            margin="normal"
                            type="text"
                            value={complement}
                            onChange={handleChange}
                            error={Boolean(errors?.complement)}
                            helperText={errors?.complement}
                            slotProps={{
                                input: {
                                    readOnly: folder?.action === 'show',
                                },
                            }}
                        />
                    </Grid>
                    <Grid size={12}>
                        <TextField
                            fullWidth
                            name="city"
                            label="Cidade"
                            margin="normal"
                            type="text"
                            value={city}
                            onChange={handleChange}
                            error={Boolean(errors?.city)}
                            helperText={errors?.city}
                            slotProps={{
                                input: {
                                    readOnly: folder?.action === 'show',
                                },
                            }}
                        />
                    </Grid>
                    <Grid size={{xs: 12, md: 6}}>
                        {
                            folder?.action === 'show' ? (
                                <TextField
                                    fullWidth
                                    name="state"
                                    label="Estado"
                                    margin="normal"
                                    type="text"
                                    value={state?.label ?? ''}
                                    slotProps={{
                                        input: {
                                            readOnly: true,
                                        },
                                    }}
                                />
                            ) : (
                                <Autocomplete
                                    fullWidth
                                    value={state}
                                    onChange={handleChange}
                                    options={OPTIONS}
                                    noOptionsText="Digite um estado do Brasil"
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            name="state"
                                            label="Estado"
                                            margin="normal"
                                            error={Boolean(errors?.state)}
                                            helperText={errors?.state}
                                        />
                                    )}
                                />
                            )
                        }
                    </Grid>
                    <Grid size={{xs: 12, md: 6}}>
                        <TextField
                            fullWidth
                            name="cep"
                            label="CEP"
                            margin="normal"
                            type="text"
                            value={cepMask(cep)}
                            onKeyUp={handleCep}
                            onChange={handleChange}
                            error={Boolean(errors?.cep)}
                            helperText={errors?.cep}
                            slotProps={{
                                htmlInput: {
                                    maxLength: 9,
                                },
                                input: {
                                    readOnly: folder?.action === 'show',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            {
                (folder?.action !== 'show' || can(folder?.root === folder?.id ? 'update_root_folder' : 'update_subfolders_assets', folder?.root)) && (
                    <DialogActions>
                        <LoadingButton
                            type="submit"
                            className="px-12"
                            loading={loading}
                            disabled={Object.keys(errors).length > 0}
                        >
                            {actions[folder?.action]}
                        </LoadingButton>
                    </DialogActions>
                )
            }
        </Dialog>
    );
}

export default CreateOrEditFolder;

export {CreateOrEditFolder};