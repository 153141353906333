import clsx from 'clsx';
import {Link} from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import {TreeContext} from '../../services';
import {forwardRef, useContext} from 'react';
import {Box, Button, Divider, IconButton, Tooltip, Typography} from '@mui/material';
import {TreeItem2Icon, TreeItem2Provider, useTreeItem2} from '@mui/x-tree-view';
import {
    CustomTreeItemContent,
    CustomTreeItemGroupTransition,
    CustomTreeItemIconContainer,
    CustomTreeItemRoot
} from './CustomTreeItem';
import AddIcon from '@mui/icons-material/Add';
import AirIcon from '@mui/icons-material/Air';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import ErrorOutlinedIcon from '../../icons/ErrorOutlinedIcon';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import WaterDropIcon from '@mui/icons-material/WaterDropOutlined';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturingOutlined';

const StyledTreeItem = forwardRef((props, ref) => {
    const {
        itemId,
        label,
        children,
        disabled,
        ...other
    } = props;

    const {
        getRootProps,
        getContentProps,
        getIconContainerProps,
        getLabelProps,
        getGroupTransitionProps,
        status,
        publicAPI,
    } = useTreeItem2({itemId, children, label, disabled, rootRef: ref});

    const {
        id,
        type,
        name,
        status: labelStatus,
        root,
    } = publicAPI.getItem(itemId);

    const {can} = useAuth();
    const statuses = {
        null: {
            color: 'warning',
            icon: NewReleasesOutlinedIcon,
        },
        true: {
            color: 'success',
            icon: VerifiedOutlinedIcon,
        },
        false: {
            color: 'error',
            icon: ErrorOutlinedIcon,

        },
    };
    const statusColor = statuses[labelStatus].color;
    const StatusIcon = statuses[labelStatus].icon;
    const isMonitor = ['lubricator', 'breather', 'level-display'].includes(type);
    const canMange = can('manage_user_folder', root);
    const canRemove = can('remove_monitor_assets', root);
    const canCreate = can('create_subfolders_assets', root);
    const canUpdate = can('update_subfolders_assets', root);
    const canInstall = can('install_monitor_assets', root);

    const {isConfiguring, setMenu, setShowConfirmDisassociate} = useContext(TreeContext);
    const icons = {
        'folder': FolderIcon,
        'machine': PrecisionManufacturingIcon,
        'subset': SettingsIcon,
        'component': RadioButtonCheckedIcon,
        'lubricator': WaterDropIcon,
        'breather': AirIcon,
        'level-display': DeviceThermostatIcon,
    }
    let LabelIcon = icons[type];

    if (id === 0) {
        LabelIcon = HomeIcon;
    }

    const item = (
        <TreeItem2Provider itemId={itemId}>
            <CustomTreeItemRoot {...getRootProps(other)} isConfiguring={isConfiguring}>
                <CustomTreeItemContent
                    {...getContentProps({
                        className: clsx('content gap-0', {
                            'Mui-expanded': status.expanded,
                            'Mui-focused': status.focused,
                            'Mui-disabled': status.disabled,
                        }),
                    })}
                >
                    <CustomTreeItemIconContainer className="mr-0" {...getIconContainerProps()}>
                        <TreeItem2Icon status={status}/>
                    </CustomTreeItemIconContainer>
                    <Divider className="w-[16px] h-[2px] -ml-[1.4px]" sx={theme => ({borderBottom: `2px solid ${theme.palette.text.primary}`})}/>
                    <Box
                        className="flex grow items-center py-[7px] pl-4 pr-0.5 bg-white"
                        sx={theme => ({
                            border: `1px solid ${isConfiguring ? theme.palette.primary.main : theme.palette[statusColor].tree}`,
                            borderRadius: '.5rem',
                            ...(isConfiguring && id !== 0 && !isMonitor && (canCreate || (canInstall && type !== 'folder')) && {
                                borderRadius: '.5rem 0 0 .5rem',
                            })
                        })}
                    >
                        <Box component={LabelIcon} color="text.secondary" className="mr-1" sx={{fontSize: 30}}/>
                        <Typography
                            {
                                ...getLabelProps({
                                    variant: 'body2',
                                    className: 'grow flex items-end font-medium'
                                })
                            }
                        />
                        {
                            isConfiguring && id !== 0 && (
                                <Box className="flex items-center justify-end">
                                    {
                                        !isMonitor &&
                                        (canUpdate || canMange || type === 'folder') && (
                                            <Tooltip title="Editar">
                                                <IconButton
                                                    className="p-1"
                                                    onClick={event => {
                                                        event.stopPropagation();

                                                        setMenu({
                                                            open: true,
                                                            anchorEl: event.currentTarget,
                                                            data: {type, id, name, root},
                                                            type: 'edit',
                                                        });
                                                    }}
                                                >
                                                    <SettingsIcon fontSize="inherit"/>
                                                </IconButton>
                                            </Tooltip>
                                        )
                                    }
                                </Box>
                            )
                        }
                    </Box>
                    {
                        !isConfiguring && (
                            <>
                                <Divider className="w-[6px] h-[1px]" sx={theme => ({borderBottom: `1px solid ${theme.palette.text.primary}`})}/>
                                <Box bgcolor={statusColor + '.tree'} className="flex items-center justify-center h-[34px] w-[34px] text-white p-[11px] rounded-lg">
                                    <StatusIcon className="m-1" sx={{fontSize: 22}}/>
                                </Box>
                            </>
                        )
                    }
                    {
                        isConfiguring && id !== 0 && (
                            <Box className="flex items-center justify-end">
                                {
                                    isMonitor ? (
                                        canRemove && (
                                            <>
                                            <Divider className="w-[6px] h-[1px]" sx={theme => ({borderBottom: `1px solid ${theme.palette.text.primary}`})}/>
                                                <Tooltip title="Remover">
                                                    <IconButton
                                                        className="text-white h-[34px] w-[34px] p-[11px] rounded-lg bg-primary-main"
                                                        onClick={event => {
                                                            event.preventDefault();

                                                            setShowConfirmDisassociate({open: true, type, id});
                                                        }}
                                                    >
                                                        <ErrorOutlinedIcon fontSize="inherit" color="whitw"/>
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        )
                                    ) : (
                                        (canCreate || (canInstall && type !== 'folder')) && (
                                            <Tooltip title="Criar">
                                                <Button
                                                    className="p-0 w-[48px] h-[48px]"
                                                    sx={{
                                                        minWidth: 40,
                                                        borderTopLeftRadius: 0,
                                                        borderBottomLeftRadius: 0,
                                                    }}
                                                    onClick={event => {
                                                        event.stopPropagation();

                                                        setMenu({
                                                            open: true,
                                                            anchorEl: event.currentTarget,
                                                            data: {type, id, root},
                                                            type: 'create',
                                                        });
                                                    }}
                                                >
                                                    <AddIcon/>
                                                </Button>
                                            </Tooltip>
                                        )
                                    )
                                }
                            </Box>
                        )
                    }
                </CustomTreeItemContent>
                {children && (
                    <CustomTreeItemGroupTransition {...getGroupTransitionProps()} />
                )}
            </CustomTreeItemRoot>
        </TreeItem2Provider>
    );

    if (isMonitor) {
        return (
            <Link to={`/${type}s/${id}`} className="no-underline">
                {item}
            </Link>
        );
    }

    return item;
});

export default StyledTreeItem;

export {StyledTreeItem};