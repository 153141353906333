import {
    Autocomplete,
    Box,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    IconButton,
    TextField,
    Typography
} from '@mui/material';
import {useAuth} from '../hooks';
import {phoneMask} from '../utils';
import Grid from '@mui/material/Grid2';
import {LoadingButton} from '@mui/lab';
import BtnClose from './BtnClose';
import {AlertContext, api} from '../services';
import {filterObject} from '../utils/filterObject';
import {useContext, useEffect, useState} from 'react';
import {BtnShowPassword, FindCompany, FindFolder} from './index';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

function CreateOrEditUser({loadUsers, manageUser, onClose}) {
    const {handleLogout, setLoadingBackdrop} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [errors, setErrors] = useState({});
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [emailNotifications, setEmailNotifications] = useState(true);
    const [phone, setPhone] = useState('');
    const [phoneNotifications, setPhoneNotifications] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, setLoading] = useState(false);
    const [roles, setRoles] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [folders, setFolders] = useState([]);
    const [role, setRole] = useState([]);
    const [count, setCount] = useState(1);

    function handleChange(event, newValue, index = null) {
        let name = event.target.name ?? 'role';

        if (name === 'name') setName(event.target.value);
        else if (name === 'email') setEmail(event.target.value);
        else if (name === 'phone') setPhone(event.target.value);
        else if (name === 'phone_notifications') setPhoneNotifications(event.target.checked);
        else if (name === 'email_notifications') setEmailNotifications(event.target.checked);
        else if (name === 'role') {
            setRole(role => {
                const newRole = {...role};

                newRole[index] = newValue;

                return newRole;
            });
        }

        name = name === 'phone_notifications' ? 'phone' : name;

        if (errors[name]) {
            if ((name === 'password' && errors['password_confirmation']) || (name === 'password_confirmation' && errors['password'])) {
                setErrors(filterObject(errors, index => index !== 'password' && index !== 'password_confirmation'));
            } else {
                setErrors(filterObject(errors, index => index !== name));
            }
        }
    }

    function handlePhone(event) {
        const input = event.target;

        input.value = phoneMask(input.value);
    }

    function handleSubmit(event) {
        event.preventDefault();

        setLoading(true);

        const password = event?.target?.password?.value;
        const password_confirmation = event?.target?.password_confirmation?.value;
        const phoneNumeric = phone.replace(/[^0-9]/g, '');

        if (name.length < 3) {
            setErrors({
                name: 'O nome precisa ter pelo menos 3 caracteres.',
            });
            setLoading(false);
            return;
        }

        if (!email) {
            setErrors({
                email: 'O e-mail é obrigatório.',
            });
            setLoading(false);
            return;
        }

        if ((!manageUser.id || password.length < 0) && password.length < 8) {
            setErrors({
                password: 'A senha precisa ter pelo menos 8 caracteres.',
            });
            setLoading(false);
            return;
        }

        if (password !== password_confirmation) {
            setErrors({
                password_confirmation: 'A senha e a confirmação precisam ser iguais.',
            });
            setLoading(false);
            return;
        }

        if (phone.length > 0 && phone.length < 11) {
            setErrors({
                name: 'O telefone precisa ter 11 dígitos.',
            });
            setLoading(false);
            return;
        }

        if (phoneNotifications && !phoneNumeric) {
            setErrors({
                phone: 'O telefone é obrigatório se desejar receber notificações.',
            });
            setLoading(false);
            return;
        }

        let apiMethod = api.post;
        let apiEndpoint = '/users';

        if (manageUser.id) {
            apiMethod = api.put;
            apiEndpoint += '/' + manageUser.id;
        }

        apiMethod(apiEndpoint, {
            name,
            email,
            email_notifications: emailNotifications,
            phone: phoneNumeric,
            phone_notifications: phoneNotifications,
            password,
            password_confirmation,
            is_admin: isAdmin,
            folders,
            role,
        })
            .then(response => {
                newAlert(response.data?.message, 'success');

                event.target.reset();

                if (!manageUser.id) {
                    setName('');
                    setEmail('');
                    setPhone('');
                    setPhoneNotifications(true);
                    setCompanies([]);
                    setFolders([]);
                    setRole([]);
                }

                onClose();
                loadUsers();
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) setErrors(error.response?.data?.errors || {errors: {}});
                else {
                    const action = manageUser.id ? 'editar' : 'criar';

                    newAlert(error.response?.data?.message ?? `Erro ao ${action} o usuário. Tente novamente mais tarde.`);
                }
            })
            .finally(() => setLoading(false));
    }

    function handleSetFolder(i, value) {
        setFolders(folders => {
            const newFolders = {...folders};

            newFolders[i] = value;

            return newFolders
        })
    }

    function handleSetCompany(i, value) {
        setCompanies(companies => {
            const newCompanies = {...companies};

            newCompanies[i] = value;

            return newCompanies
        })
    }

    useEffect(() => {
        api.get('/roles/all')
            .then(response => setRoles(response.data?.data))
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else newAlert(error.response?.data?.message ?? 'Erro ao carregar as funções. Tente novamente mais tarde.');
            })
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        if (!manageUser.id) return;

        setLoadingBackdrop(true);

        api.get('/users/' + manageUser.id)
            .then(response => {
                response = response.data?.data;

                setName(response?.name ?? '');
                setEmail(response?.email ?? '');
                setEmailNotifications(response?.email_notifications ?? true);
                setPhone(response?.phone ?? '');
                setPhoneNotifications(response?.phone_notifications ?? true);
                setIsAdmin(Boolean(response?.is_admin));
                setErrors({});

                if (!Boolean(response?.is_admin)) {
                    setCount(response?.folders?.length ?? 1);
                    setCompanies(response?.companies ?? []);
                    setFolders(response?.folders ?? []);
                    setRole(response?.roles ?? []);
                }
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else {
                    newAlert(error.response?.data?.message ?? 'Erro ao tentar carregar o usuário. Tente novamente mais tarde.');
                }
            })
            .finally(() => setLoadingBackdrop(false))
        // eslint-disable-next-line
    }, [manageUser.id]);

    return (
        <Dialog
            maxWidth="md"
            open={Boolean(manageUser?.open)}
            onClose={onClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
                noValidate: true,
            }}
        >
            <DialogTitle>
                <Box>
                    Detalhes da Conta
                </Box>
                <BtnClose onClick={onClose}/>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid size={{xs: 12, md: 6}}>
                        <TextField
                            required
                            autoFocus
                            fullWidth
                            name="name"
                            label="Nome"
                            margin="normal"
                            type="text"
                            value={name}
                            onChange={handleChange}
                            error={Boolean(errors?.name)}
                            helperText={errors?.name}
                        />
                    </Grid>
                    <Grid size={{xs: 12, md: 6}}>
                        <TextField
                            required
                            fullWidth
                            name="email"
                            label="E-mail"
                            margin="normal"
                            type="email"
                            value={email}
                            onChange={handleChange}
                            error={Boolean(errors?.email)}
                            helperText={errors?.email}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="email_notifications"
                                    checked={emailNotifications}
                                    onChange={handleChange}
                                />
                            }
                            label="Deseja receber notificações por e-mail"
                        />
                    </Grid>
                    <Grid size={{xs: 12, md: 6}}>
                        <TextField
                            fullWidth
                            name="password"
                            label="Senha"
                            margin="normal"
                            type={showPassword ? 'text' : 'password'}
                            required={Boolean(!manageUser.id)}
                            onChange={handleChange}
                            error={Boolean(errors?.password)}
                            helperText={errors?.password}
                            slotProps={{
                                input: {
                                    endAdornment: <BtnShowPassword setShow={setShowPassword} show={showPassword}/>,
                                },
                            }}
                        />
                    </Grid>
                    <Grid size={{xs: 12, md: 6}}>
                        <TextField
                            fullWidth
                            name="password_confirmation"
                            label="Confirme a senha"
                            margin="normal"
                            type={showPassword ? 'text' : 'password'}
                            required={Boolean(!manageUser.id)}
                            onChange={handleChange}
                            error={Boolean(errors?.password_confirmation)}
                            helperText={errors?.password_confirmation}
                            slotProps={{
                                input: {
                                    endAdornment: <BtnShowPassword setShow={setShowPassword} show={showPassword}/>,
                                },
                            }}
                        />
                    </Grid>
                    <Grid size={{xs: 12, md: 6}}>
                        <TextField
                            fullWidth
                            name="phone"
                            label="Telefone"
                            margin="normal"
                            type="tel"
                            value={phoneMask(phone)}
                            onChange={handleChange}
                            required={phoneNotifications}
                            error={Boolean(errors?.phone)}
                            helperText={errors?.phone}
                            onKeyUp={handlePhone}
                            slotProps={{
                                htmlInput: {
                                    maxLength: 15,
                                },
                            }}
                        />
                        <FormControlLabel
                            className="mr-0"
                            control={
                                <Checkbox
                                    name="phone_notifications"
                                    checked={phoneNotifications}
                                    onChange={handleChange}
                                />
                            }
                            label="Deseja receber notificações por WhatsApp ou SMS"
                        />
                    </Grid>
                    <Grid size={12} className="mt-2">
                        <Typography variant="h5" className="-mb-3">
                            Permissões
                        </Typography>
                        <Divider className="flex justify-around" textAlign="right">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isAdmin}
                                        onChange={event => setIsAdmin(event.target.checked)}
                                    />
                                }
                                label="É administrador"
                            />
                        </Divider>
                    </Grid>
                    {
                        !isAdmin && (
                            <>
                                {
                                    [...Array(count)].map((x, i) => (
                                        <Grid size={12} key={i}>
                                            <Grid container spacing={2}>
                                                <Grid size={12}>
                                                    <FindCompany
                                                        company={companies[i] ?? null}
                                                        setCompany={value => handleSetCompany(i, value)}
                                                        setFolder={value => handleSetFolder(i, value)}
                                                    />
                                                </Grid>
                                                <Grid size={{xs: 12, md: 6}}>
                                                    <FindFolder
                                                        folder={folders[i] ?? null}
                                                        company={companies[i] ?? null}
                                                        setFolder={value => handleSetFolder(i, value)}
                                                    />
                                                </Grid>
                                                <Grid size={{xs: 12, md: 6}}>
                                                    <Autocomplete
                                                        fullWidth
                                                        value={role[i] ?? null}
                                                        options={roles}
                                                        loading={roles.length === 0}
                                                        loadingText="Carregando ..."
                                                        onChange={(event, newValue) => handleChange(event, newValue, i)}
                                                        noOptionsText="Nenhuma função encontrada"
                                                        getOptionLabel={option => option.name}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                fullWidth
                                                                name="role"
                                                                label="Função"
                                                                margin="normal"
                                                                error={Boolean(errors[`role.${i}`])}
                                                                helperText={errors[`role.${i}`]}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                            {
                                                i + 1 !== count &&
                                                <Grid size={12}>
                                                    <Divider className="mt-6"/>
                                                </Grid>
                                            }
                                        </Grid>
                                    ))
                                }
                                <Grid size={12}>
                                    <Grid container justifyContent="center">
                                        <IconButton
                                            color="error"
                                            aria-label="Remover a última empresa"
                                            onClick={() => setCount(count - 1)}
                                            disabled={count <= 1}
                                        >
                                            <RemoveIcon/>
                                        </IconButton>
                                        <IconButton
                                            color="success"
                                            aria-label="Adicionar mais uma empresa"
                                            onClick={() => setCount(count + 1)}
                                        >
                                            <AddIcon/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </>
                        )
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    type="submit"
                    className="px-12"
                    loading={loading}
                    disabled={Object.keys(errors).length > 0}
                >
                    Salvar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default CreateOrEditUser;

export {CreateOrEditUser};