import {IconButton, InputAdornment} from '@mui/material';
import {VisibilityOffOutlined, VisibilityOutlined} from '@mui/icons-material';

const BtnShowPassword = ({setShow, show}) => (
    <InputAdornment
        position="end"
        className="mr-2"
    >
        <IconButton
            aria-label={show ? 'Ocultar Senha' : 'Mostrar Senha'}
            onClick={() => setShow(!show)}
            edge="end"
        >
            {show ? <VisibilityOffOutlined/> : <VisibilityOutlined/>}
        </IconButton>
    </InputAdornment>
);

export default BtnShowPassword;

export {BtnShowPassword};