import {
    Box,
    Breadcrumbs,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Paper,
    Skeleton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import Grid from '@mui/material/Grid2';
import {useParams} from 'react-router-dom';
import {useAuth, useTitle} from '../hooks';
import {useContext, useEffect, useState} from 'react';
import {AlertContext, api, TreeContext} from '../services';
import {ActiveAlerts, BtnClose, ConfirmDisassociateMonitor, LatestStatus, RefreshedAt, StyledSwitch} from '../components';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturingOutlined';

function DetailsLubricator() {
    const {id} = useParams();
    const theme = useTheme();
    const {setTitle} = useTitle();
    const {newAlert} = useContext(AlertContext);
    const {handleLogout, setLoadingBackdrop} = useAuth();
    const [lubricator, setLubricator] = useState({});
    const [engine, setEngine] = useState(true);
    const [loading, setLoading] = useState(true);
    const [manageEngine, setManageEngine] = useState(false);
    const {setShowConfirmDisassociate} = useContext(TreeContext);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => setManageEngine(false);

    function changeEngine(event) {
        if (event.target.checked) {
            sendEngine(true);
        } else {
            setManageEngine(true);
        }
    }

    function sendEngine(status) {
        setLoadingBackdrop(true);

        const action = status ? 'active' : 'disable';
        const text = status ? 'ativar' : 'desativar';

        api.patch(`/lubricators/${id}/engine/${action}`)
            .then(() => setEngine(status))
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 403) newAlert(`Você não tem permissão para ${text} o motor desse lubrificador.`);
                else newAlert(`Erro ao tentar ${text} o motor desse lubrificador. Tente novamente mais tarde.`);
            })
            .finally(() => setLoadingBackdrop(false));
    }

    function loadLubricator() {
        setLoading(true);

        api.get('/lubricators/' + id)
            .then(response => setLubricator(response?.data?.data))
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 404) newAlert('O lubrificador solicitado não foi encontrado.');
                else if (code === 403) newAlert('Você não tem permissão para acessar os detalhes desse lubrificador.');
                else newAlert('Erro ao tentar carregar os detalhes desse lubrificador. Tente novamente mais tarde.');

                setLubricator({});
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => setTitle('Ver Lubrificador', 'Detalhes do Lubrificador #' + id), [id, setTitle]);

    // eslint-disable-next-line
    useEffect(loadLubricator, [id]);

    if (!loading && !lubricator) return;

    return (
        <>
            <Box className="self-start w-full">
                <Box className="my-2">
                    {
                        loading ? (
                            <Skeleton height={42} width="100%" variant="rounded"/>
                        ) : (
                            lubricator.location ? (
                                <Breadcrumbs>
                                    {
                                        lubricator.location?.folders?.map((folder, i) => (
                                            <Chip
                                                key={i}
                                                label={folder}
                                                icon={i === 0 ?
                                                    <CorporateFareIcon fontSize="small" /> :
                                                    <FolderIcon fontSize="small" />
                                                }
                                            />
                                        ))
                                    }
                                    {
                                        lubricator?.location?.machine && (
                                            <Chip
                                                icon={<PrecisionManufacturingIcon fontSize="small" />}
                                                label={lubricator.location.machine}
                                            />
                                        )
                                    }
                                    {
                                        lubricator?.location?.subset && (
                                            <Chip
                                                icon={<SettingsIcon fontSize="small" />}
                                                label={lubricator.location.subset}
                                            />
                                        )
                                    }
                                    {
                                        lubricator?.location?.component && (
                                            <Chip
                                                icon={<RadioButtonCheckedIcon fontSize="small" />}
                                                label={lubricator.location.component}
                                            />
                                        )
                                    }
                                </Breadcrumbs>
                            ) : (
                                <Typography
                                    variant="caption"
                                    component="span"
                                    className="font-bold"
                                >
                                    Lubrificador não instalado
                                </Typography>
                            )
                        )
                    }
                </Box>
                <Grid container columnSpacing={3}>
                    <Grid container size={{xs: 12, lg: 'grow'}}>
                        <Grid container size={12} className="my-6 items-end">
                            <Grid size={6}>
                                <Typography variant="h6" className="font-medium text-primary-main">
                                    InfoLub
                                </Typography>
                            </Grid>
                            <Grid size={6}>
                                <RefreshedAt
                                    loading={loading}
                                    hour={lubricator?.hour}
                                    refresh={loadLubricator}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            size={12}
                            spacing={4}
                            className="p-9 rounded-lg bg-blue-bg justify-center"
                            sx={(theme) => ({border: `1px solid ${theme.palette.primary.main}`})}
                        >
                            <Grid size={{xs: 12, md: 6}}>
                                <Typography className="text-primary-main">
                                    Número de Série
                                </Typography>
                                <Paper className="p-2 text-center rounded text-sm">
                                    {
                                        loading ? (
                                            <Skeleton width={250}/>
                                        ) : lubricator.serial
                                    }
                                </Paper>
                            </Grid>
                            <Grid size={{xs: 12, md: 6}}>
                                <Typography className="text-primary-main">
                                    Bateria
                                </Typography>
                                <Paper className="p-2 text-center rounded text-sm">
                                    {
                                        loading ? (
                                            <Skeleton/>
                                        ) : lubricator.battery ? lubricator.battery + '%' : 'Sem informação'
                                    }
                                </Paper>
                            </Grid>
                            <Grid size={{xs: 12, md: 6}}>
                                <Typography className="text-primary-main">
                                    ID
                                </Typography>
                                <Paper className="p-2 text-center rounded text-sm">
                                    {
                                        loading ? (
                                            <Skeleton/>
                                        ) : `#${lubricator.id}`
                                    }
                                </Paper>
                            </Grid>
                            {
                                (loading || lubricator?.location) && (
                                    <Grid size={{xs: 12, md: 6}}>
                                        <Typography className="text-primary-main">
                                            Duração
                                        </Typography>
                                        <Paper className="p-2 text-center rounded text-sm">
                                            {
                                                loading ? (
                                                    <Skeleton width={65}/>
                                                ) : `${lubricator.duration} ${lubricator.duration === 1 ? 'mês' : 'meses'}`
                                            }
                                        </Paper>
                                    </Grid>
                                )
                            }
                            <Grid size={{xs: 12, md: 6}}>
                                <Typography className="text-primary-main">
                                    Capacidade Total
                                </Typography>
                                <Paper className="p-2 text-center rounded text-sm">
                                    {
                                        loading ? (
                                            <Skeleton width={40}/>
                                        ) : `${lubricator.total_capacity.toLocaleString()}g`
                                    }
                                </Paper>
                            </Grid>
                            <Grid size={{xs: 12, md: 6}}>
                                <Typography className="text-primary-main">
                                    Capacidade Atual
                                </Typography>
                                <Paper className="p-2 text-center rounded text-sm">
                                    {
                                        loading ? (
                                            <Skeleton width={40}/>
                                        ) : `${lubricator.current_capacity.toLocaleString()}g`
                                    }
                                </Paper>
                            </Grid>
                            {
                                (loading || lubricator?.location) && (
                                    <Grid size={{xs: 12, md: 6}}>
                                        <Typography className="text-primary-main">
                                            Data da Instalação
                                        </Typography>
                                        <Paper className="p-2 text-center rounded text-sm">
                                            {
                                                loading ? (
                                                    <Skeleton width={135}/>
                                                ) : lubricator.installed_at
                                            }
                                        </Paper>
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Grid
                            container
                            size={12}
                            className="rounded-lg bg-blue-bg py-7 px-6 my-8 justify-between items-center"
                            sx={(theme) => ({
                                color: theme.palette.primary.main,
                                border: `1px solid ${theme.palette.primary.main}`,
                            })}
                        >
                            <Grid size={6}>
                                <Typography className="font-medium">
                                    Controle do Motor
                                </Typography>
                            </Grid>
                            <Grid size={6} className="text-end">
                                {
                                    loading ? (
                                        <Box className="flex items-center justify-end">
                                            <Skeleton width={42} height={26} variant="rounded" className="m-0"/>
                                            <Skeleton width={100} className="ml-[20px]"/>
                                        </Box>
                                    ) : (
                                        <FormControlLabel
                                            control={
                                                <StyledSwitch checked={engine} onChange={changeEngine}/>
                                            }
                                            label={engine ? 'Motor Ativado' : 'Motor Desativado'}
                                            slotProps={{
                                                typography: {
                                                    variant: 'body2',
                                                    className: 'font-normal',
                                                    sx: {
                                                        marginLeft: '20px',
                                                    },
                                                },
                                            }}
                                        />
                                    )
                                }
                            </Grid>
                        </Grid>
                        <LatestStatus lubricator={id}/>
                    </Grid>
                    <Grid
                        container
                        size={{xs: 12, lg: 3}}
                        className="px-2.5 py-3.5 rounded-lg bg-blue-bg mt-8 lg:mt-[100.5px] flex-col"
                        sx={theme => ({
                            minWidth: 239,
                            border: `1px solid ${theme.palette.primary.main}`,
                        })}
                    >
                        <Typography variant="h6" className="font-medium text-primary-main mx-auto pt-2 mb-7">
                            Painel de Alertas
                        </Typography>
                        <ActiveAlerts
                            type="lubricator"
                            loading={loading}
                            activeAlerts={lubricator?.active_alerts}
                        />
                        {
                            (loading || (lubricator.location && lubricator.disassociate)) && (
                                <Box className="mt-2 lg:mt-auto text-center">
                                    {
                                        loading ? (
                                            <Skeleton width={207} height={37} variant="rounded"/>
                                        ) : (
                                            <>
                                                <Button
                                                    color="error"
                                                    variant="outlined"
                                                    className="bg-white"
                                                    onClick={() => setShowConfirmDisassociate({
                                                        open: true,
                                                        type: 'lubricator',
                                                        id: lubricator.id,
                                                    })}
                                                >
                                                    Remover lubrificador
                                                </Button>
                                                <ConfirmDisassociateMonitor callback={loadLubricator}/>
                                            </>
                                        )
                                    }
                                </Box>
                            )
                        }
                    </Grid>
                </Grid>
            </Box>
            <Dialog
                maxWidth="sm"
                open={manageEngine}
                onClose={handleClose}
                fullScreen={fullScreen}
            >
                <DialogTitle>
                    <Box>
                        Controle do Motor
                    </Box>
                    <BtnClose onClick={handleClose}/>
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        Você tem certeza que deseja desativar o motor?
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="justify-end">
                    <Button
                        autoFocus
                        variant="outlined"
                        onClick={handleClose}
                    >
                        Cancelar
                    </Button>
                    <LoadingButton
                        color="error"
                        loading={loading}
                        onClick={() => {
                            sendEngine(false);
                            handleClose();
                        }}
                    >
                        Desativar
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default DetailsLubricator;

export {DetailsLubricator};