import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Link,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Tooltip,
    useMediaQuery,
    useTheme
} from '@mui/material';
import {
    BtnClose,
    CreateOrEditRole,
    FilterRecords,
    ListUserFolder,
    StyledTableCell,
    StyledTableRow,
    TablePagination
} from '../components';
import env from 'react-dotenv';
import {LoadingButton} from '@mui/lab';
import {useAuth, useTitle} from '../hooks';
import {AlertContext, api} from '../services';
import {useContext, useEffect, useRef, useState} from 'react';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import MangePermissions from '../components/MangePermissions';

function ListRoles() {
    const theme = useTheme();
    const {handleLogout} = useAuth();
    const {setTitle} = useTitle();
    const [loading, setLoading] = useState(true);
    const [deleting, setDeleting] = useState(false);
    const [roles, setRoles] = useState({});
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const {newAlert} = useContext(AlertContext);
    const [showConfirmDelete, setShowConfirmDelete] = useState({open: false});
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [role, setRole] = useState({open: false, data: {}});
    const [query, setQuery] = useState('');
    const [showUsers, setShowUsers] = useState({open: false});
    const [managePermissions, setManagePermissions] = useState({open: false, data: {}});
    const interval = parseInt(localStorage.getItem('refresh') ?? env.REFRESH_DEFAULT_INTERVAL)  * 60 * 1000;
    let timeout = useRef(null);

    const handleClose = () => setShowConfirmDelete({...showConfirmDelete, open: false});

    function loadRoles() {
        clearTimeout(timeout.current);
        setLoading(true);

        api
            .get('/roles', {
                params: {
                    page: page + 1,
                    per_page: perPage,
                    query,
                }
            })
            .then(response => {
                setRoles(response.data);
                timeout.current = setTimeout(loadRoles, interval);
            })
            .catch(() => {
                setRoles({});
                newAlert('Erro ao tentar carregar funções. Tente novamente mais tarde.');
            })
            .finally(() => setLoading(false));
    }

    function confirmDelete(id, name) {
        if (id === 1) {
            newAlert('Não é possível excluir a função principal.');
            return;
        }

        setShowConfirmDelete({open: true, id, name});
    }

    function deleteRole() {
        setDeleting(true);

        api
            .delete(`/roles/${showConfirmDelete?.id}`)
            .then(response => {
                newAlert(response?.data?.message, 'success');
                setRoles(roles => {
                    const newRoles = {...roles};

                    newRoles.data = newRoles.data.filter(role => role.id !== showConfirmDelete?.id);
                    newRoles.meta.total--;

                    return newRoles;
                });
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else newAlert(error.response?.data?.message ?? 'Erro ao tentar excluir a função. Tente novamente mais tarde.');
            })
            .finally(() => {
                setDeleting(false);
                handleClose();
            });
    }

    useEffect(() => setTitle('Permissões'), [setTitle]);

    useEffect( () => {
            loadRoles();

            return () => clearTimeout(timeout.current);
            // eslint-disable-next-line
        }, [page, perPage, query]);

    return (
        <>
            <Box className="w-full">
                <FilterRecords
                    setQuery={setQuery}
                    setPage={setPage}
                    loading={loading}
                    refresh={loadRoles}
                    placeholder="Pesquisar por nome ou descrição ..."
                />
                <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="Funções" sx={{minWidth: 850}} size="small">
                        <TableHead>
                            <TableRow>
                                {
                                    [
                                        'Função',
                                        'Descrição',
                                        'Permissões',
                                        'Usuários',
                                        'Ações',
                                    ].map((text, i) => (
                                        <StyledTableCell
                                            key={i}
                                            align="center"
                                        >
                                            {text}
                                        </StyledTableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                loading ? (
                                        new Array(perPage).fill(1).map((value, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell>
                                                    <Skeleton width={100}/>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Skeleton width={200}/>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Skeleton width={115}/>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Skeleton width={75}/>
                                                </StyledTableCell>
                                                <StyledTableCell className="flex justify-center">
                                                    <Skeleton className="mx-2"/>
                                                    <Skeleton className="mx-2"/>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    ) :
                                    (
                                        (roles && roles?.data?.map(role => (
                                            <StyledTableRow key={role?.id}>
                                                <StyledTableCell>
                                                    {role?.name}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {role?.description ?? '-'}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Button
                                                        variant="outlined"
                                                        className="py-1 px-8 text-xs rounded"
                                                        onClick={() => {
                                                            setManagePermissions({
                                                                open: true,
                                                                data: {
                                                                    id: role?.id,
                                                                    permissions: role?.permissions,
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        {role?.permissions?.length === 0 ? 'Adicionar' : 'Acessar'}
                                                    </Button>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Link
                                                        underline="none"
                                                        component="button"
                                                        onClick={() => {
                                                            setShowUsers({open: true, id: role?.id, name: role?.name});
                                                        }}
                                                    >
                                                        {role?.users_count} usuário{role?.users_count !== 1 && 's'}
                                                    </Link>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Tooltip title="Editar">
                                                        <IconButton
                                                            color="warning"
                                                            aria-label="Editar"
                                                            onClick={() => {
                                                                setRole({
                                                                    open: true,
                                                                    data: {
                                                                        id: role?.id,
                                                                        name: role?.name,
                                                                        description: role?.description,
                                                                    },
                                                                });
                                                            }}
                                                        >
                                                            <EditIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Apagar">
                                                        <IconButton
                                                            color="error"
                                                            aria-label="Apagar"
                                                            onClick={() => confirmDelete(role?.id, role?.name)}
                                                        >
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )))
                                    )
                            }
                            {
                                !loading && !roles?.data?.length && (
                                    <TableRow>
                                        <TableCell colSpan={5} align="center">
                                            <Alert severity="warning">
                                                Nenhuma função cadastrada.
                                            </Alert>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={5}
                                    rowsPerPage={perPage}
                                    setPerPage={setPerPage}
                                    page={page}
                                    setPage={setPage}
                                    count={roles?.meta?.total ?? 0}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                <Box className="text-end w-full mt-8">
                    <Button onClick={() => setRole({open: true})}>
                        Nova Função
                    </Button>
                </Box>
            </Box>
            <Dialog
                maxWidth="sm"
                open={Boolean(showConfirmDelete?.open)}
                onClose={handleClose}
                fullScreen={fullScreen}
            >
                <DialogTitle>
                    <Box>
                        Excluir a função {showConfirmDelete?.name}?
                    </Box>
                    <BtnClose onClick={handleClose}/>
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        A exclusão dessa função removerá as permissões de todos os usuários associados a ela.
                    </DialogContentText>
                    <div className="text-center font-bold mt-3">
                        ESTA AÇÃO NÃO PODERÁ SER DESFEITA!
                    </div>
                </DialogContent>
                <DialogActions className="justify-end">
                    <Button
                        autoFocus
                        variant="outlined"
                        onClick={handleClose}
                    >
                        Cancelar
                    </Button>
                    <LoadingButton
                        color="error"
                        loading={deleting}
                        onClick={deleteRole}
                    >
                        Deletar
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <MangePermissions
                manage={managePermissions}
                onClose={() => setManagePermissions({...managePermissions, open: false})}
                fullScreen={fullScreen}
                loadRoles={loadRoles}
            />
            <CreateOrEditRole
                role={role}
                onClose={() => setRole({...role, open: false})}
                fullScreen={fullScreen}
                loadRoles={loadRoles}
            />
            <ListUserFolder
                showUsers={showUsers}
                setShowUsers={setShowUsers}
                fullScreen={fullScreen}
            />
        </>
    );
}

export default ListRoles;

export {ListRoles};