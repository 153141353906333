import {api} from '../services/api';
import {useEffect, useState} from 'react';
import {useAuth, useTitle} from '../hooks';
import {useParams, useSearchParams} from 'react-router-dom';
import {Alert, AlertTitle, CircularProgress} from '@mui/material';

function VerifyEmail() {
    const {user_id: userId, email} = useParams();
    const [searchParams] = useSearchParams();
    const expires = parseInt(searchParams.get('expires'), 10);
    const signature = searchParams.get('signature');
    const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0), 10);
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState({});
    const {user, refreshUser} = useAuth();
    const {setTitle} = useTitle();

    useEffect(() => setTitle('Confirmar e-mail'), [setTitle]);

    useEffect(() => {
        if (!user?.new_email) {
            setAlert({
                severity: 'success',
                title: 'E-mail já confirmado.',
                message: 'Seu e-mail já foi confirmado, nenhuma ação adicional é necessária.'
            });
            setLoading(false);
            return;
        }

        if (timestamp > expires) {
            setAlert({
                severity: 'error',
                title: 'Link expirado.',
                message: 'O link para troca de e-mail expirou, por favor, solicite um novo link.'
            });
            setLoading(false);

            return;
        }

        api
            .get(`/email/verify/${userId}/${email}`, {
                params: {
                    signature,
                    expires
                }
            })
            .then(response => {
                setAlert({
                    severity: 'success',
                    title: response?.data?.title,
                    message: response?.data?.message
                });
                refreshUser();
            })
            .catch(() => {
                setAlert({
                    severity: 'error',
                    title: 'Erro ao confirmar e-mail.',
                    message: 'Não foi possível confirmar o e-mail, tente novamente mais tarde.'
                });
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line
    }, []);

    if (loading) return <CircularProgress className="m-auto"/>;

    return (
        <Alert className="mx-auto" severity={alert.severity}>
            <AlertTitle>
                {alert.title}
            </AlertTitle>
            {alert.message}
        </Alert>
    );
}

export default VerifyEmail;

export {VerifyEmail};